<template>
    <div>
        <DataTableHeader
            :pagination="pagination"
            :searchAttributes="searchAttributes"
            :selectedSearchAttributes.sync="selectedSearchAttributes"
            :disabled="loading"
        />
        <DataTableSkeletonLoader :loading="initialLoad"/>
        <v-data-table
            v-show="!initialLoad"
            :headers="headers"
            :items="entities"
            :dense="$vuetify.breakpoint.xs && !loading"
            :loading="loading"
            :loading-text="$t('components.noData.fetchingEntries')"
            :options.sync="options"
            :server-items-length="pagination.total"
            :items-per-page="pagination.per_page"
            :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
            :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
            :item-class="highlightRowIfUserBalanceLow"
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ deviceType === 'computers' ? 'PC' : 'PS' }}</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                </v-toolbar>    
            </template>
            <template v-slot:item.username="{ item }">
                <v-tooltip
                    color="transparent"
                    v-if="item.last_polling_date && !wasPolledInLastNMinutes(item, 1)"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            color="yellow"
                            medium
                            class="mr-2"
                        >
                        mdi-alert
                        </v-icon>
                    </template>
                    <v-card class="elevation-2 px-2 py-2">
                        <span> {{ $t('entities.users.connectivityIssue', {minutes: Math.round((new Date() - new Date(item.last_polling_date)) / 60000)}) }} </span>
                    </v-card>
                </v-tooltip>
                <span>{{ item.username }}</span>
            </template>
            <template v-slot:item.arena_id="{ item }">
                <template v-if="item.arena_id">
                    {{ item.arena_id }}
                </template>
            </template>
            <template v-slot:item.balance="{ item }">
                <v-skeleton-loader
                    :loading="item.balance === -0.5"
                    type="image"
                    width="30px"
                    height="15px"
                    class="d-inline-block"
                    :style="{ marginTop : `${item.balance === -0.5 ? '2' : '0'}px`}"
                >
                   {{ addThousandSeparator(item.balance) }}
                </v-skeleton-loader>
                <CoinImage class="ml-1"/>
            </template>
            <template v-slot:item.base_rate="{ item }">
                {{ addThousandSeparator(item.rate_per_minute) }}
                <CoinImage/>/min
                ({{ addThousandSeparator(item.base_rate) }} <CoinImage/>/h)
                <template v-if="item.pass">
                    <br>
                    Pass: {{ item.pass.valid_from_local }} - {{ item.pass.valid_to_local }}
                </template>
                <v-tooltip
                    v-if="item.rate_per_minute >= 0 || item.negative_balance < 0"
                    color="transparent"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            :color="shouldChangeRowStyle(item) ? 'white' : 'primary'"
                            small
                        >
                            mdi-information
                        </v-icon>
                    </template>
                    <v-card class="elevation-2 px-2 py-1">
                        <v-simple-table
                            class="do-not-apply-hover"
                            dense
                        >
                            <template v-slot:default>
                                <tbody>
                                    <tr v-if="item.free_until">
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.startForFreeUntil') }}: <span class="font-weight-bold">{{ fromUtcDateTimeToCurrent(item.free_until, true, true) }}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="item.negative_balance < 0">
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.negativeBalance') }}: <span class="font-weight-bold">{{ addThousandSeparator(item.negative_balance) }} <CoinImage/></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">{{ $t('entities.sessions.bonuses.rank') }}</td>
                                        <td class="font-weight-bold">
                                            {{ item.rank_bonus }}%
                                        </td>
                                        <td>{{ $t('entities.sessions.bonuses.reward') }}</td>
                                        <td class="font-weight-bold pr-0">
                                            {{ item.reward_bonus }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">{{ $t('entities.sessions.bonuses.subscription') }}</td>
                                        <td class="font-weight-bold">
                                            {{ item.subscription_bonus }}%
                                        </td>
                                        <td>{{ $t('entities.sessions.bonuses.arena') }}</td>
                                        <td class="font-weight-bold pr-0">
                                            {{ item.arena_bonus }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">{{ $t('entities.sessions.bonuses.skill') }}</td>
                                        <td class="font-weight-bold">
                                            {{ item.skill_bonus }}%
                                        </td>
                                        <td>{{ $t('entities.sessions.bonuses.legend') }}</td>
                                        <td class="font-weight-bold pr-0">
                                            {{ item.legend_bonus }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.bonuses.formula') }} = 1 - (1 - <b>{{ !!item.legend_bonus? item.legend_bonus : item.rank_bonus }}%</b>) * (1 - <b>{{ item.reward_bonus }}%</b>) * (1 - <b>{{ item.subscription_bonus }}%</b>) * (1 - <b>{{ item.skill_bonus }}%</b>) * (1 - <b>{{ item.arena_bonus }}%</b>)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.bonuses.total') }}: <span class="font-weight-bold">{{ item.total_bonus }}%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.daily_boost.daily_boost') }}: <span class="font-weight-bold">{{ item.daily_boost.percentage }}%</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-tooltip>
            </template>
            <template v-slot:item.started_at="{ item }">
                {{ fromUtcDateTimeToCurrent(item.started_at, true, true) }} ({{ Math.round(item.duration / 60) }} min)
            </template>
            <template v-if="deviceType === 'computers'" v-slot:item.currentlyPlaying="{ item }">
                <div v-if="item.games && item.games.length > 0">
                    <v-tooltip top color="transparent" >
                        <template v-slot:activator="{ on }">
                            <v-avatar size="36px">
                                <img
                                    v-on="on"
                                    :src="item.games[0].game.icon || item.games[0].game.image"
                                    alt=""
                                />
                            </v-avatar>
                        </template>
                        <v-card>{{ item.games[0].game.name }}</v-card>
                    </v-tooltip>
                    <span> ({{ Math.round(item.games[0].duration / 60) }} min)</span>
                </div>
                <div v-else>
                    <v-tooltip top color="transparent" >
                        <template v-slot:activator="{ on }">
                            <v-avatar size="36px">
                                <v-icon color="warning" medium v-on="on">mdi-alert</v-icon>
                            </v-avatar>
                        </template>
                        <v-card>{{$t('entities.games.unknownGame')}}</v-card>
                    </v-tooltip>
                    <span> ({{ Math.round(item.duration / 60) }} min)</span>
                </div>
            </template>
            <template v-slot:item.action="{ item }">
                <UserActions
                        :key="item.id"
                        :entity="{ id: item.user_id }"
                        :userSessionInitial="item"
                        :viewButton=true
                        :editButton=false
                        :shopButton=true
                        :simulateSessionButton=false
                        :sessionButton=true
                        :deleteButton=false
                        @user-session-action="refresh"
                        @user-deleted="refresh"
                    />
            </template>
            <template v-slot:no-data>
                 <NoData
                    :pagination="pagination"
                    :typing="typing"
                    :fetchEntities="fetchEntities"
                />
            </template>
        </v-data-table>
        <DataTableFooter
            :pagination="pagination"
            :disabled="loading"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import {mapActions} from "vuex";
import NoData from '@/components/crud/NoData.vue';
import CoinImage from '@/components/CoinImage.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Session from "@/models/main/Session";

import thousandSeparator from '@/mixins/thousand-separator';
import { axiosCatch } from "@/mixins/crud/methods/axios-catch";
import { paginationWatch } from "@/mixins/crud/watch/pagination";
import convertDate from '@/mixins/convert-date';
import convertCase from '@/mixins/convert-case';
import isRole from '@/mixins/crud/computed/isRole';
import UserActions from "@/components/UserActions.vue";
import {EventBus} from "@/eventBus";

export default Vue.extend({

    props: {
        deviceType: {
            type: String
        }
    },

    mixins: [
        thousandSeparator,
        convertCase,
        axiosCatch,
        convertDate,
        isRole,
        paginationWatch,
    ],

    components: {
        UserActions,
        CoinImage,
        NoData,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
    },

    data: () => ({
        interval: null,
        initialLoad: true,
        loading: false,
        typing: false,
        loadingIndexes: [],
        entities: [],
        editedEntity: {
            id: null,
            user_id: null,
            achievements: [],
            quests: [],
            events: [],
        },
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['Username'],
    }),

    computed: {

        headers() {
            const headers = [
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username' },
                { text: this.$t('entities.devices.entity'), align: 'left', value: 'device_id' },
                { text: this.$t('generalAttributes.coins'), align: 'left', value: 'balance', sortable: false },
                { text: this.$t('playingNow.headers.rate'), align: 'left', value: 'base_rate', sortable: false },
                { text: this.$t('entities.sessions.attributes.startedAt'), align: 'left', value: 'started_at' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];

            if (!this.$store.getters.arena) {
                headers.splice(0, 0, { text: this.$t('miscellaneous.arena'), align: 'left', value: 'arena.name', sortable: false })
            }

            if (this.deviceType === "computers") {
                headers.splice(5, 0, { text: this.$t('adminDashboard.currentlyPlaying'), align: 'left', value: 'currentlyPlaying' });
            }

            return headers;
        },
        filteredEvents() {
            const filteredEvents = this.events;
            if (!this.isAdmin) {
                // Remove legend from list if not admin
                filteredEvents.splice(filteredEvents.findIndex(a => a.name.toLowerCase() === "legend"), 1)
            }
            return filteredEvents;
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
                { label: this.$t('entities.devices.entity'), value: 'Device' },
            ]
        },
        arenaName() {
            return this.$store.getters.arena ? `${this.titleToKebabCase(this.$store.getters.arena.name)}` : '';
        }
    },


    mounted() {
        this.refresh();
        this.interval = setInterval(() => {
            this.refresh();
        }, 30000) // Every 30 seconds
    },

    created() {
        EventBus.$on('scanUserDialogClosed', this.refresh);
    },

    beforeDestroy() {
        clearInterval(this.interval);
        EventBus.$off('scanUserDialogClosed');
    },

    methods: {

        ...mapActions(['showSnackbar']),

        async fetchEntities() {
            try {
                let append = this.setParams();

                this.loading = true;
                let response;
                // If searched by specific device, change param, else, default search by device type
                if (append.includes('device=')) {
                    append = append.replace('device=', 'hostname=');
                }
                // Always fetch only active sessions
                append += `&deviceId=${this.deviceType}&active=true`;
                if (this.isAdmin) {
                    if (this.$store.getters.arena) {
                        response = await Session.api.getByArena(this.$store.getters.arena.id, `${append}`);
                    } else {
                        response = await Session.api.get(`${append}`);
                    }
                } else {
                    response = await Session.api.getByArena(this.$store.getters.arena ? this.$store.getters.arena.id : -1, `${append}`);
                }
                const data = response.data;
                if (this.deviceType === "computers") {
                    await this.fetchSessionDetails(data);
                } else {
                    this.entities = data.content.map(SessionDTO => {
                        const session = new Session(SessionDTO);
                        session.balance = SessionDTO.coin_balance
                        return session;
                    });
                }
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`User sessions API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },

        async fetchSessionDetails(data) {
            const sessionIds = data.content.map(SessionDTO => SessionDTO.id).join(',');
            if (!sessionIds) {
                return;
            }
            let sessionGamesListData = [];
            try {
                const response = await Session.api.getSessionsGames(this.$store.getters.arena.id, `session_ids=${sessionIds}&currently_playing=true`);
                sessionGamesListData = response.data.data;
            } catch (e) {
                console.warn(`failed to fetch session details`);
                console.log(e);
            }
            this.entities = data.content.map(SessionDTO => {
                const session = new Session(SessionDTO);
                session.balance = SessionDTO.coin_balance
                const sessionGamesData = sessionGamesListData.find(s => s.sessionId === SessionDTO.id);
                session.games = [];
                if (sessionGamesData) {
                    session.games = sessionGamesData.games;
                }
                return session;
            });

        },
        shouldChangeRowStyle(session) {
            return session.rate_per_minute > 0 && session.balance < session.negative_balance + 300;
        },
        highlightRowIfUserBalanceLow(session) {
            if (this.shouldChangeRowStyle(session)) {
                return 'error white--text do-not-apply-hover';
            }
        },
        refresh() {
            this.fetchEntities();
        },
        wasPolledInLastNMinutes(item, minutes = 1) { 
            return (new Date().getTime() - new Date(item.last_polling_date).getTime()) < 60000*minutes;
        }

    }
});

</script>
