import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class GamesApi {

    public static route = process.env.VUE_APP_ARENA_HUB_BASE_URI;
    public static entity = 'games';

    public static async get(params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?${params}`);
    }

    public static async search(params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?${params}`);
    }

    public static async create(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}`, data);
    }

    public static async update(id: string, data: object): Promise<AxiosResponse> {
        return await axios.patch(`${this.route}/${this.entity}/${id}`, data);
    }
}
