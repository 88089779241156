import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        authenticated: false,
        arena: null,
        selectedCashRegisterLabel: null,
        country: 'hr',
        snackbar: { show: false, color: "", message: "", timeout: 3000, icon: 'check_circle' }
    },
    mutations: {
        setAuthenticated(state, authenticated) {
            state.authenticated = authenticated;
        },
        setArena(state, arena) {
            state.arena = arena;
        },
        setSelectedCashRegisterLabel(state, selectedCashRegisterLabel) {
            state.selectedCashRegisterLabel = selectedCashRegisterLabel;
        },
        setCountry(state, country) {
            state.country = country;
        },
        SHOW_SNACKBAR(state, snackbar) {
          state.snackbar = snackbar;
        },
        HIDE_SNACKBAR(state) {
          state.snackbar.show = false;
        },
    },
    getters: {
        authenticated: (state) => {
            return state.authenticated;
        },
        arena: (state) => {
            return state.arena;
        },
        selectedCashRegisterLabel: (state) => {
            return state.selectedCashRegisterLabel;
        },
        country: (state) => {
            return state.country;
        },
        snackbar: state => state.snackbar
    },
    actions: {
        showSnackbar({ commit }, { message, color = "success", timeout = 3000, icon = "check_circle" }) {
            commit("SHOW_SNACKBAR", {
                show: true,
                color: color,
                message: message,
                timeout: timeout,
                icon: icon
            });
        },
        hideSnackbar({ commit }) {
            commit('HIDE_SNACKBAR');
        },
    },
    modules: {}
})
