<template>
    <div>
        <v-card class="px-6 pt-1">
            <v-row
                align="center"
                class="mt-4 pb-4"
            >
                <v-col
                    cols="auto"
                    class="pr-1"
                >
                    <p class="mt-3">
                        {{ $t('entities.events.title') }} |
                    </p>
                </v-col>
                <v-col
                    cols="auto"
                    class="px-0"
                >
                    <v-skeleton-loader
                        :loading="loading"
                        type="image"
                        width="15px"
                        height="15px"
                        class="d-inline-block"
                    >
                        <p class="d-inline-block mb-1">
                            {{ addThousandSeparator(pagination.total) }}
                        </p>
                    </v-skeleton-loader>
                </v-col>
                <v-col 
                    cols="auto"
                    class="pl-0 mb-3"
                >
                    <RefreshButton
                        :refresh="refresh"
                        :loading="loading"
                    />
                </v-col>
                <v-col
                    cols="auto"
                    class="grow"
                >
                    <DataTableHeader
                        :text="$t('components.rowsPerPage.entries')"
                        :pagination="pagination"
                        :searchAttributes="searchAttributes"
                        :selectedSearchAttributes.sync="selectedSearchAttributes"
                        :disabled="loading"
                        :rowPadding="'0'"
                    />
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xsOnly ? '12' : 'auto'">
                    <v-dialog
                        v-model="editDialog"
                        max-width="1000px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                :block="$vuetify.breakpoint.xsOnly"
                                color="primary"
                                class="mb-2"
                                dark
                            >
                                {{ $t('miscellaneous.add') }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text class="px-0 pb-0">
                                <EditToolbar
                                    :formTitle="formTitle"
                                    :closeEditDialog="closeEditDialog"
                                />
                                <v-container>
                                    <v-card class="px-4 pb-4">
                                        <v-form
                                            @submit.prevent="save"
                                            method="POST"
                                            ref="form"
                                        >
                                            <RequiredFields/>
                                            <DialogImage
                                                :image="editedEntity.image"
                                                :name="editedEntity.name"
                                            />
                                            <v-row align="center">
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-flex>
                                                        <ImageInput
                                                            :file.sync="file"
                                                            :alreadyHasImage="editedEntity.image"
                                                        />
                                                    </v-flex>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-switch
                                                        :input-value="editedEntity.active"
                                                        @change="v => (editedEntity.active = v)"
                                                        :label="$t('entities.games.attributes.active')"
                                                        prepend-icon="done"
                                                        inset
                                                    ></v-switch>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.title"
                                                        @change="v => (editedEntity.title = v)"
                                                        :rules="[required]"
                                                        :label="$t('entities.events.attributes.title')"
                                                        prepend-icon="mdi-format-title"
                                                    ></v-text-field>
                                                    <span class="required-fields">*</span>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                >
                                                    <v-text-field
                                                        :value="editedEntity.subtitle"
                                                        @change="v => (editedEntity.subtitle = v)"
                                                        :label="$t('entities.events.attributes.subtitle')"
                                                        prepend-icon="mdi-text"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-dialog
                                                        v-model="modalStarting"
                                                        :return-value.sync="dateStarting"
                                                        ref="dialog_starting"
                                                        width="290px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="editedEntity.starts_at"
                                                                :rules="[required]"
                                                                :label="$t('entities.events.attributes.startsAt')"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                prepend-icon="mdi-calendar-start"
                                                                readonly
                                                            ></v-text-field>
                                                        </template>
                                                        <v-tabs
                                                            v-model="tab"
                                                            background-color="primary"
                                                            icons-and-text
                                                            centered
                                                            dark
                                                            grow
                                                        >
                                                            <v-tabs-slider color="primary lighten-2"></v-tabs-slider>
                                                            <v-tab href="#tab-1">
                                                                <v-icon>event</v-icon>
                                                            </v-tab>
                                                            <v-tab href="#tab-2">
                                                                <v-icon>more_time</v-icon>
                                                            </v-tab>
                                                        </v-tabs>
                                                        <v-tabs-items v-model="tab">
                                                            <v-tab-item
                                                                value="tab-1"
                                                                class="text-capitalize"
                                                            >
                                                                <v-date-picker
                                                                    v-model="dateStarting"
                                                                    :locale="$i18n.locale"
                                                                    scrollable
                                                                >
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        @click="modalStarting = false"
                                                                        color="primary"
                                                                        text
                                                                    >
                                                                        {{ $t('miscellaneous.cancel') }}
                                                                    </v-btn>
                                                                </v-date-picker>
                                                            </v-tab-item>
                                                            <v-tab-item value="tab-2">
                                                                <v-time-picker
                                                                    v-model="timeStarting"
                                                                    format="24hr"
                                                                ></v-time-picker>
                                                                <v-flex class="text-right mt-6 pb-2">
                                                                    <v-btn
                                                                        @click="modalStarting = false"
                                                                        color="primary"
                                                                        text
                                                                    >
                                                                        {{ $t('miscellaneous.cancel') }}
                                                                    </v-btn>
                                                                    <v-btn
                                                                        @click="$refs.dialog_starting.save(dateStarting)"
                                                                        color="primary"
                                                                        text
                                                                    >
                                                                        {{ $t('miscellaneous.confirm') }}
                                                                    </v-btn>
                                                                </v-flex>
                                                            </v-tab-item>
                                                        </v-tabs-items>
                                                    </v-dialog>
                                                    <span class="required-fields">*</span>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="position-relative"
                                                >
                                                    <v-dialog
                                                        v-model="modalEnding"
                                                        :return-value.sync="dateEnding"
                                                        ref="dialog_ending"
                                                        width="290px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="editedEntity.ends_at"
                                                                :rules="[required]"
                                                                :label="$t('entities.events.attributes.endsAt')"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                prepend-icon="mdi-calendar-end"
                                                                readonly
                                                            ></v-text-field>
                                                        </template>
                                                        <v-tabs
                                                            v-model="tab"
                                                            background-color="primary"
                                                            icons-and-text
                                                            centered
                                                            dark
                                                            grow
                                                        >
                                                            <v-tabs-slider color="primary lighten-2"></v-tabs-slider>
                                                            <v-tab href="#tab-1">
                                                                <v-icon>event</v-icon>
                                                            </v-tab>
                                                            <v-tab href="#tab-2">
                                                                <v-icon>more_time</v-icon>
                                                            </v-tab>
                                                        </v-tabs>
                                                        <v-tabs-items v-model="tab">
                                                            <v-tab-item
                                                                value="tab-1"
                                                                class="text-capitalize"
                                                            >
                                                                <v-date-picker
                                                                    v-model="dateEnding"
                                                                    :locale="$i18n.locale"
                                                                    max="2100-01-01"
                                                                    scrollable
                                                                >
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        @click="modalEnding = false"
                                                                        color="primary"
                                                                        text
                                                                    >
                                                                        {{ $t('miscellaneous.cancel') }}
                                                                    </v-btn>
                                                                </v-date-picker>
                                                            </v-tab-item>
                                                            <v-tab-item value="tab-2">
                                                                <v-time-picker
                                                                    v-model="timeEnding"
                                                                    format="24hr"
                                                                ></v-time-picker>
                                                                <v-flex class="text-right mt-6 pb-2">
                                                                    <v-btn
                                                                        @click="modalEnding = false"
                                                                        color="primary"
                                                                        text
                                                                    >
                                                                        {{ $t('miscellaneous.cancel') }}
                                                                    </v-btn>
                                                                    <v-btn
                                                                        @click="$refs.dialog_ending.save(dateEnding)"
                                                                        color="primary"
                                                                        text
                                                                    >
                                                                        {{ $t('miscellaneous.confirm') }}
                                                                    </v-btn>
                                                                </v-flex>
                                                            </v-tab-item>
                                                        </v-tabs-items>
                                                    </v-dialog>
                                                    <span class="required-fields">*</span>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    class="position-relative"
                                                >
                                                    <v-select
                                                        :value="editedEntity.arena_ids"
                                                        @change="v => (editedEntity.arena_ids = v)"
                                                        :items="arenas"
                                                        :rules="[v => !!v.length || $t('validation.atLeastOneArena')]"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                        item-text="name"
                                                        item-value="id"
                                                        multiple
                                                        solo
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('miscellaneous.chooseArenas') }}
                                                            <span :style="{ color: 'red' }">*</span>
                                                        </template>
                                                        <template v-slot:prepend-item>
                                                            <v-list-item
                                                                @click="toggle"
                                                                ripple
                                                            >
                                                                <v-list-item-action>
                                                                    <v-icon :color="editedEntity.arena_ids.length > 0 ? 'primary darken-4' : ''">{{ icon }}</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{ $t(`components.dataTableHeader.${selectedAllArenas ? 'de' : ''}selectAll`) }}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider class="mt-2"></v-divider>
                                                        </template>
                                                        <template v-slot:selection="{ item, index }">
                                                            <span
                                                                v-if="index < 5"
                                                                :class="{ 'pl-1' : index !== 0}"
                                                            >
                                                                 <v-chip
                                                                     color="primary"
                                                                     small
                                                                 >
                                                                    {{ item.name }}
                                                                </v-chip>
                                                            </span>
                                                            <span
                                                                v-if="index === 5"
                                                                class="grey--text caption pl-1"
                                                            >
                                                                (+{{ editedEntity.arena_ids.length - 1 }} {{ $t('miscellaneous.others') }})
                                                            </span>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <tiptap-vuetify
                                                        v-model="editedEntity.content"
                                                        :extensions="extensions"
                                                        :placeholder="$t('entities.events.attributes.content')"
                                                        :toolbar-attributes="{ color: $vuetify.theme.dark ? 'primary' : '' }"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <ValidationErrors
                                                v-if="validationErrors.length"
                                                :errors="validationErrors"
                                                class="mt-6"
                                            />
                                        </v-form>
                                    </v-card>
                                </v-container>
                            </v-card-text>
                            <EditCardActions
                                :save="save"
                                :saving="saving"
                                :closeEditDialog="closeEditDialog"
                            />
                        </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="deleteDialog"
                        max-width="1000px"
                    >
                        <v-card>
                            <v-card-text class="px-0 pb-0">
                                <DeleteToolbar
                                    :text="$t('entities.events.deleteEntity')"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                                <v-container>
                                    <DialogImage
                                        :image="toDeleteEntity.image"
                                        :name="toDeleteEntity.name"
                                    />
                                    <v-card>
                                        <v-simple-table
                                            class="do-not-apply-hover"
                                            dense
                                        >
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ $t('entities.events.attributes.title') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.title }}
                                                        </td>
                                                        <td>{{ $t('entities.events.attributes.active') }}</td>
                                                        <td class="font-weight-bold">
                                                            <Boolean :boolean="toDeleteEntity.active"/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.events.attributes.subtitle') }}</td>
                                                        <td
                                                            colspan="3"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toDeleteEntity.subtitle || '/' }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="deleteDialog">
                                                        <td>{{ $t('entities.events.attributes.startsAt') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.starts_at }}
                                                        </td>
                                                        <td>{{ $t('entities.events.attributes.endsAt') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.ends_at }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.events.attributes.content') }}</td>
                                                        <td
                                                            colspan="3"
                                                            class="font-weight-bold pt-4"
                                                        >
                                                            <div v-html="toDeleteEntity.content"/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                </v-container>
                            </v-card-text>
                            <DeleteCardActions
                                :deleteEntity="deleteEntity"
                                :deleting="deleting"
                                :closeDeleteDialog="closeDeleteDialog"
                            />
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-divider/>
            <v-row
                v-if="!loading && entities.length"
                class="mt-4"
            >
                <v-col
                    v-for="(item, key) in entities"
                    :key="key"
                    cols="12"
                    sm="3"
                    class="d-flex flex-column"
                >
                    <v-card class="flex d-flex flex-column">
                        <v-img
                            :src="item.image"
                            :alt="item.title"
                            height="200px"
                            class="text-left"
                        >
                            <v-chip
                                class="font-weight-bold mt-4 ml-2"
                                color="primary"
                            >
                                <small>{{ item.starts_at }}</small>
                            </v-chip>
                        </v-img>
                        <v-card-title class="justify-center">
                            {{ item.title }}
                        </v-card-title>
                        <v-card-text>
                            <p>
                                {{ item.subtitle }}
                            </p>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions class="px-4">
                            <v-spacer/>
                            <EditButton
                                :text="$t('entities.events.editEntity')"
                                :item="item"
                                :openEditDialog="openEditDialog"
                            />
                            <DeleteButton
                                :text="$t('entities.events.deleteEntity')"
                                :item="item"
                                :openDeleteDialog="openDeleteDialog"
                            />
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row
                v-else-if="!loading && !entities.length"
                class="mt-4"
            >
                <v-col class="text-center">
                    <p v-if="typing">
                        {{ $t('components.noData.fetchingEntries') }}
                    </p>
                    <p v-else-if="!typing && pagination.search.length">{{ $t('components.noData.filtered') }}: {{ pagination.search }}</p>
                    <p 
                        v-else
                        class="mb-0"
                    >
                        {{ $t('components.noData.noEntriesFound') }}
                    </p>
                    <v-btn
                        v-if="!typing && pagination.search.length"
                        @click="pagination.search = ''"
                        color="primary"
                        class="mb-3"
                        outlined
                    >
                        {{ $t('components.noData.clear') }}
                    </v-btn>
                    <v-btn
                        v-else-if="!typing && !pagination.search.length"
                        @click="fetchEntities()"
                        color="primary"
                        class="mt-3 mb-3"
                        outlined
                    >
                        {{ $t('components.noData.tryAgain') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                v-else
                class="mt-4"
            >
                <CardSkeletonLoader :count="12"/>
            </v-row>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>

    </div>
</template>

<script>

import Vue from 'vue';
import {mapActions} from "vuex";
import Boolean from '@/components/Boolean.vue';
import ImageInput from '@/components/ImageInput.vue';
import CardSkeletonLoader from '@/components/CardSkeletonLoader.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DialogImage from '@/components/DialogImage';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Event from '@/models/social/Event';
import Arena from '@/models/setup/Arena';
import FilesApi from '@/services/misc/FilesApi';
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';

import isRole from '@/mixins/crud/computed/isRole';
import thousandSeparator from '@/mixins/thousand-separator';
import required from '@/mixins/rules/required';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import user from "@/mixins/crud/computed/user";

export default Vue.extend({
    components: {
        Boolean,
        ImageInput,
        RefreshButton,
        CardSkeletonLoader,
        EditButton,
        EditToolbar,
        EditCardActions,
        DeleteButton,
        DeleteToolbar,
        DeleteCardActions,
        DialogImage,
        DataTableHeader,
        DataTableFooter,
        RequiredFields,
        ValidationErrors,
        TiptapVuetify
    },

    mixins: [
        paginationWatch,
        editDialog,
        deleteDialog,
        thousandSeparator,
        isRole,
        deleteEntity,
        axiosCatch,
        required,
        user,
    ],

    data: () => ({
        loading: false,
        saving: false,
        deleting: false,
        typing: false,
        apiURI: process.env.VUE_APP_SOCIAL_BASE_URI,
        entity: 'events',
        route: 'events',
        tab: null,
        modalStarting: false,
        modalEnding: false,
        dateStarting: null,
        dateEnding: null,
        timeStarting: null,
        timeEnding: null,
        entities: [],
        arenas: [],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 12,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['createdAt'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['Title'],
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        file: null,
        image: null,
        editedEntity: {
            id: null,
            title: '',
            active: 1,
            subtitle: '',
            arena_ids: [],
            content: '',
            image: null,
            starts_at: '',
            ends_at: '',
        },
        defaultEntity: {
            id: null,
            title: '',
            active: 1,
            subtitle: '',
            arena_ids: [],
            content: '',
            image: null,
            starts_at: '',
            ends_at: '',
        },
        toDeleteEntity: {
            id: null,
            title: '',
            active: 1,
            subtitle: '',
            arena_ids: [],
            content: '',
            image: null,
            starts_at: '',
            ends_at: '',
        },
        extensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak
        ],
    }),

    computed: {
        selectedAllArenas() {
            return this.editedEntity.arena_ids.length === this.arenas.length
        },
        selectedSomeArenas() {
            return this.editedEntity.arena_ids.length > 0 && !this.selectedAllArenas
        },
        icon() {
            if (this.selectedAllArenas) return 'mdi-close-box'
            if (this.selectedSomeArenas) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.events.attributes.title'), value: 'Title' },
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
    },

    watch: {
        editDialog(val) {
            if (val) {
                if (this.editedEntity.starts_at) {
                    const [date, time] = this.editedEntity.starts_at.split(' ');
                    this.dateStarting = date;
                    this.timeStarting = time;
                }
                if (this.editedEntity.ends_at) {
                    const [date, time] = this.editedEntity.ends_at.split(' ');
                    this.dateEnding = date;
                    this.timeEnding = time;
                }
            } else {
                this.dateStarting = null;
                this.timeStarting = null;
                this.dateEnding = null;
                this.timeEnding = null;
            }
        },
        modalStarting() {
            this.tab = 'tab-1';
            if (this.dateStarting && this.timeStarting) {
                this.editedEntity.starts_at = `${this.dateStarting} ${this.timeStarting}`;
            }
        },
        dateStarting() {
            this.tab = 'tab-2';
        },
        modalEnding() {
            this.tab = 'tab-1';
            if (this.dateEnding && this.timeEnding) {
                this.editedEntity.ends_at = `${this.dateEnding} ${this.timeEnding}`;
            }
        },
        dateEnding() {
            this.tab = 'tab-2';
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {

        ...mapActions(['showSnackbar']),

        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}&sort=createdAt,DESC`) {
            try {
                this.loading = true;
                let response;
                if (this.isAdmin) {
                    if (this.$store.getters.arena) {
                        response = await Event.api.getByArena(this.$store.getters.arena.id, `${append}`);
                    } else {
                        response = await Event.api.get(`${append}`);
                    }
                } else {
                    response = await Event.api.getByArena(this.$store.getters.arena ? this.$store.getters.arena.id : -1, `${append}`);
                }
                const data = response.data;
                this.entities = data.content.map(EventDTO => new Event(EventDTO));
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn('Events API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async fetchArenas() {
            try {
                this.loading = true;
                let search = 'size=100'
                if (!this.isAdmin) {
                    search += `&employee_id=${this.user.id}`;
                }
                const response = await Arena.api.get(search);
                this.arenas = response.data.data.map(ArenaDTO => new Arena(ArenaDTO));
            } catch (e) {
                console.warn('Arenas API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.file) {
                    await FilesApi.upload('social', this.file)
                        .then((response) => {
                            this.file = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar({color:'error', message: this.$t('miscellaneous.fileUploadFailed')});
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar({color:'error', message: this.$t('miscellaneous.fileUploadFailed')});
                        });
                }

                const data = {
                    title: this.editedEntity.title,
                    content: this.editedEntity.content,
                    active: this.editedEntity.active ? 1 : 0,
                    arena_ids: this.editedEntity.arena_ids,
                    starts_at: `${this.dateStarting} ${this.timeStarting}`,
                    ends_at: `${this.dateEnding} ${this.timeEnding}`,
                    image: this.image || this.editedEntity.image,
                };

                if (this.editedEntity.subtitle) {
                    data.subtitle = this.editedEntity.subtitle;
                }

                if (this.editedIndex > -1) {
                    await Event.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar({color:'success', message: this.$t('entities.events.notifications.update')});
                            this.closeEditDialog();
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await Event.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.closeEditDialog();
                            this.showSnackbar({color:'success', message: this.$t('entities.events.notifications.creation')});
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Event Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        toggle() {
            this.$nextTick(() => {
                if (this.selectedAllArenas) {
                    this.editedEntity.arena_ids = [];
                } else {
                    this.editedEntity.arena_ids = this.arenas.map(arena => arena.id);
                }
            })
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            this.image = null;
            this.editDialog = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        refresh() {
            this.fetchEntities();
            this.fetchArenas();
        }
    }
});

</script>

<style>

.accent--text button {
    text-transform: capitalize !important;
}

.v-picker__title {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
}

</style>
