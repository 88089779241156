<template>
    <v-snackbar
        v-model="showLocal"
        :color="color"
        :timeout="timeout"
        :top="$vuetify.breakpoint.xsOnly ? false : 'top'"
        right="right"
        transition="slide-x-reverse-transition"
        shaped
    >
        <v-row align="center" class="py-2">
            <v-col cols="1" class="py-0">
                <v-icon color="white">{{ icon }}</v-icon>
            </v-col>
            <v-col cols="9" class="py-0 pl-8">
                <p class="font-weight-medium mb-0 white--text">{{ message }}</p>
            </v-col>
            <v-col cols="2" class="py-0">
                <v-btn @click="hideSnackbar" class="font-weight-bold" dark text>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-snackbar>
</template>

<script>

import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

export default Vue.extend({
    props: {
        show: {
            type: Boolean
        },
        color: {
            type: String,
            default: 'success'
        },
        message: {
            type: String,
        },
        timeout: {
            type: Number,
            default: 3000
        },
        icon: {
            type: String,
            default: 'check_circle'
        }
    },

    watch: {
        show() {
            if (this.show) {
                setTimeout(() => {
                    this.$emit('update:show', false)
                }, this.timeout);
            }
        }
    },

    computed: {
        showLocal: {
            get: function() {
                return this.show;
            },
            set: function(value) {
                this.$emit('showChange', value)
            }
        },
        ...mapState(['snackbar'])
    },
    methods: {
        ...mapActions(['hideSnackbar'])
    },

})

</script>