import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class KpiUserApi {

    public static route = process.env.VUE_APP_KPI_BASE_URI;
    public static entity = 'op-dashboard';

    public static async getMostPlayedByUser(userId: number, startDate: Date, endDate: Date): Promise<AxiosResponse> {
        const fmtStartDate = startDate.toISOString().split('T')[0];
        const fmtEndDate = endDate.toISOString().split('T')[0];
        return await axios.get(`${this.route}/${this.entity}/games/most-played-by-user?user_id=${userId}&start_date=${fmtStartDate}&end_date=${fmtEndDate}`);
    }

    public static async getMostSoldToUser(userId: number, startDate: Date, endDate: Date): Promise<AxiosResponse> {
        const fmtStartDate = startDate.toISOString().split('T')[0];
        const fmtEndDate = endDate.toISOString().split('T')[0];
        return await axios.get(`${this.route}/${this.entity}/sales/most-sold-to-user?user_id=${userId}&start_date=${fmtStartDate}&end_date=${fmtEndDate}`);
    }
}