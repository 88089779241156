import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class UsersApi {

    public static route = process.env.VUE_APP_CORE_BASE_URI;
    public static achievements = process.env.VUE_APP_ACHIEVEMENTS_BASE_URI;
    public static gamification = process.env.VUE_APP_GAMIFICATION_BASE_URI;
    public static subscriptions = process.env.VUE_APP_SUBSCRIPTIONS_BASE_URI;
    public static entity = 'users';

    public static async get(params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?${params}`);
    }

    public static async getOld(params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}/bad-imports?${params}`);
    }

    public static async create(data: object): Promise<AxiosResponse>{
        return await axios.post(`${this.route}/${this.entity}`, data);
    }

    public static async find(id: bigint): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}/${id}`);
    }

    public static async findByIds(userIds: bigint[]): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}/by-id?ids=${userIds.join(',')}`);
    }

    public static async findByUsername(username: string): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}/by-username/${username}`);
    }

    public static async update(id: bigint, data: object): Promise<AxiosResponse>{
        return await axios.put(`${this.route}/${this.entity}/${id}`, data);
    }

    public static async import(badImportId: bigint, data: object): Promise<AxiosResponse>{
        return await axios.post(`${this.route}/${this.entity}/bad-imports/${badImportId}`, data, { headers: { 'content-type': `application/json` }});
    }

    public static async delete(id: bigint): Promise<AxiosResponse> {
        return await axios.delete(`${this.route}/${this.entity}/${id}`);
    }

    public static async getWallet(userId: bigint): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}/${userId}/wallet`);
    }

    public static async getAchievements(id: bigint, append: string): Promise<AxiosResponse>{
        return await axios.get(`${this.achievements}/${this.entity}/${id}/achievements?size=100${append}`);
    }

    public static async getQuests(id: bigint, append: string): Promise<AxiosResponse>{
        return await axios.get(`${this.achievements}/${this.entity}/${id}/quests?size=100${append}`);
    }

    public static async getInventoryItems(id: bigint): Promise<AxiosResponse>{
        return await axios.get(`${this.gamification}/legacy/${this.entity}/${id}/items`);
    }

    public static async getBonuses(id: bigint): Promise<AxiosResponse>{
        return await axios.get(`${this.gamification}/${this.entity}/${id}/bonus`);
    }

    public static async getSubscriptions(id: bigint): Promise<AxiosResponse>{
        return await axios.get(`${this.subscriptions}/${this.entity}/${id}/subscriptions`);
    }

    public static async getSettings(id: bigint): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}/${id}/settings`);
    }

    public static async getSettingsByNamespace(id: bigint, namespace: string): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}/${id}/settings/${namespace}`);
    }

    public static async updateSettingByNamespace(id: bigint, namespace: string, key: string, data: object): Promise<AxiosResponse>{
        return await axios.patch(`${this.route}/${this.entity}/${id}/settings/${namespace}/${key}`, data);
    }

    public static async awardEvent(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.achievements}/events`, data);
    }

    public static async resetPassword(id: bigint): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}/${id}/reset-password`);
    }

    public static async approveIdentity(id: bigint): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}/${id}/identity/approve`);
    }

    public static async rejectIdentity(id: bigint): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}/${id}/identity/reject`);
    }
}
