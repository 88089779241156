<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.carts.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-dialog
                            v-model="viewDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.carts.viewEntity') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="viewDialog = false"
                                            icon
                                            dark
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr v-if="editedEntity.invoice_id">
                                                            <td>{{ $t('entities.invoices.entity') }}</td>
                                                            <td colspan="6">
                                                                <v-chip
                                                                    class="font-weight-bold"
                                                                    color="green darken-2"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ editedEntity.invoice.number }}
                                                                </v-chip>
                                                            </td>
                                                        </tr>
                                                        <tr v-else-if="editedEntity.order_id">
                                                            <td>{{ $t('entities.orders.entity') }}</td>
                                                            <td colspan="6">
                                                                <v-chip
                                                                    class="font-weight-bold"
                                                                    color="green darken-2"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ editedEntity.order_id }}
                                                                </v-chip>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('miscellaneous.gross') }}</td>
                                                            <td
                                                                colspan="2"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ format(editedEntity.gross, true, editedEntity.arena.company.country.currency) }}
                                                            </td>
                                                            <td>{{ $t('miscellaneous.net') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ format(editedEntity.net, true, editedEntity.arena.company.country.currency) }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                            <td
                                                                colspan="2"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ fromUtcDateTimeToCurrent(editedEntity.created_at, true, true) }}
                                                            </td>
                                                            <td>{{ $t('entities.carts.attributes.expiresAt') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ fromUtcDateTimeToCurrent(editedEntity.expires_at, true, true) }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">{{ $t('miscellaneous.items') }}</td>
                                                            <td colspan="2">{{ $t('generalAttributes.quantity') }}</td>
                                                            <td colspan="2">{{ $t('generalAttributes.price') }}</td>
                                                            <td
                                                                colspan="2"
                                                                class="text-right"
                                                            >
                                                                {{ $t('generalAttributes.totalPrice') }}
                                                            </td>
                                                        </tr>
                                                        <template v-if="editedEntity.items && editedEntity.items.length">
                                                            <tr
                                                                v-for="(item, key) in editedEntity.items"
                                                                :key="key"
                                                                class="font-weight-bold"
                                                            >
                                                                <td colspan="2">
                                                                    <template v-if="item.name !== 'Coins' && item.category.name === 'Coins'">
                                                                        {{ item.name }} ({{ item.category_attributes ? item.category_attributes[0]['value'] : '' }} <CoinImage/>)
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ item.name }}
                                                                    </template>
                                                                </td>
                                                                <td colspan="2">
                                                                    {{ addThousandSeparator(item.quantity) }}
                                                                    <template v-if="item.name === 'Coins'">
                                                                        <CoinImage/>
                                                                    </template>
                                                                </td>
                                                                <td colspan="2">
                                                                  <template v-if="item.name === 'Coins'">
                                                                    {{ editedEntity.arena.company.country.coins_per_currency }} <CoinImage/> / 1 {{ editedEntity.arena.company.country.currency }}
                                                                  </template>
                                                                  <template v-else>
                                                                    {{ format(item.price, true, editedEntity.arena.company.country.currency) }}
                                                                  </template>
                                                                </td>
                                                                <td
                                                                    colspan="2"
                                                                    class="text-right"
                                                                >
                                                                    {{ format(item.total_price, true, editedEntity.arena.company.country.currency) }}
                                                                </td>
                                                            </tr>
                                                        </template>
                                                        <template v-else>
                                                            <tr>
                                                                <td
                                                                    colspan="7"
                                                                    class="font-weight-bold"
                                                                >
                                                                    /
                                                                </td>
                                                            </tr>
                                                        </template>
                                                        <tr>
                                                            <td colspan="5"></td>
                                                            <td>{{ $t('miscellaneous.net') }}</td>
                                                            <td class="text-right font-weight-bold">{{ format(editedEntity.net, true, editedEntity.arena.company.country.currency) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5"></td>
                                                            <td>{{ $t('miscellaneous.gross') }}</td>
                                                            <td class="text-right font-weight-bold">{{ format(editedEntity.gross, true, editedEntity.arena.company.country.currency) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        @click="viewDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                        {{ $t('miscellaneous.ok') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.invoice_id="{ item }">
                    <template v-if="item.invoice_id">
                        <span>{{ $t('entities.invoices.entity') }}: {{ item.invoice.number }}</span>
                    </template>
                    <template v-else-if="item.order_id">
                        <span>{{ $t('entities.orders.entity') }}: {{ item.order.number }}</span>
                    </template>
                    <template v-else>
                        <span>/</span>
                    </template>
                </template>
                <template v-slot:item.user="{ item }">
                    <span>{{ item.user ? item.user.username || '/'  : '/' }}</span>
                </template>
                <template v-slot:item.gross="{ item }">
                    <span><b>{{ format(item.gross, true, item.arena.company.country.currency) }}</b></span>
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
                </template>
                <template v-slot:item.expires_at="{ item }">
                    {{ fromUtcDateTimeToCurrent(item.expires_at, true, true) }}
                </template>
                <template v-slot:item.action="{ item }">
                    <ViewButton
                        :text="$t('entities.carts.viewEntity')"
                        :item="item"
                        :openViewDialog="openViewDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
    </div>
</template>

<script>

import Vue from 'vue';
import {mapActions} from "vuex";
import CoinImage from '@/components/CoinImage.vue';
import NoData from '@/components/crud/NoData.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import ViewButton from '@/components/crud/view/ViewButton.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Cart from '@/models/shop/Cart';

import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import thousandSeparator from '@/mixins/thousand-separator';
import currency from '@/mixins/currency';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import convertDate from '@/mixins/convert-date';
import isRole from '@/mixins/crud/computed/isRole';

export default Vue.extend({
    components: {
        NoData,
        CoinImage,
        RefreshButton,
        ViewButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
    },

    mixins: [
        paginationWatch,
        convertDate,
        currency,
        thousandSeparator,
        isRole,
        axiosCatch
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        viewDialog: false,
        typing: false,
        entities: [],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['User'],
        editedIndex: -1,
        editedEntity: {
            id: '',
            invoice_id: null,
            order_id: null,
            arena: {
                id: '',
                name: '',
                company: {
                    country: {
                        currency: ''
                    }
                }
            },
            user: {
                id: '',
                name: ''
            },
            items: [],
            gross: 0,
            net: 0,
            created_at: '',
            expires_at: '',
        },
        defaultEntity: {
            id: '',
            invoice_id: null,
            order_id: null,
            arena: {
                id: '',
                name: '',
                company: {
                    country: {
                        currency: ''
                    }
                }
            },
            user: {
                id: '',
                name: ''
            },
            items: [],
            gross: 0,
            net: 0,
            created_at: '',
            expires_at: '',
        },
    }),

    computed: {
        headers() {
            const headers = [
                { text: this.$t('entities.carts.belongsTo'), align: 'left', value: 'invoice_id', sortable: false },
                { text: this.$t('miscellaneous.user'), align: 'left', value: 'user', sortable: false },
                { text: this.$t('miscellaneous.gross'), align: 'left', value: 'gross' },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at' },
                { text: this.$t('entities.carts.attributes.expiresAt'), align: 'left', value: 'expires_at' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];

            if (!this.$store.getters.arena) {
                headers.splice(0, 0, { text: this.$t('miscellaneous.arena'), align: 'left', value: 'arena.name', sortable: false })
            }

            return headers;
        },
        searchAttributes() {
            return [
                { label: this.$t('miscellaneous.user'), value: 'User' },
            ]
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        ...mapActions(['showSnackbar']),

        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}`) {
            try {
                this.loading = true;
                // If not admin and arena not chosen, fetch non-existing arena
                let query = '&arena_id=';
                if (this.isAdmin) {
                    query += this.$store.getters.arena ? this.$store.getters.arena.id : '';
                } else {
                    query += this.$store.getters.arena ? this.$store.getters.arena.id : '-1';
                }
                const response = await Cart.api.get(1, `${query}${append}`);
                this.entities = response.data.data.map(CartDTO => new Cart(CartDTO));
                response.data.meta.per_page = parseInt(response.data.meta.per_page);
                Object.assign(this.pagination, response.data.meta);
            } catch (e) {
                console.warn('Carts API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        openViewDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            this.viewDialog = true;
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>
