<template>
    <div>
        <GlobalButton
            :route="'/faulty-users'"
            :text="$t('entities.users.faultyUsers')"
            icon="people"
        />
        <v-card class="elevation-2">
            <v-row
                class="mb-2 px-4"
                align="center"
                justify="start"
            >
                <v-col
                    cols="12"
                    md="2"
                    class="text-center"
                >
                    <RowsPerPage
                        :pagination="pagination"
                        :disabled="loading"
                    />
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    class="text-center"
                >
                    <v-text-field
                        v-model="pagination.search"
                        @click:append="() => (pagination.search = '')"
                        @keydown.native.escape="pagination.search = ''"
                        :label="selectedSearchAttributes.length ? $t('components.dataTableHeader.search') : $t('components.dataTableHeader.searchDisabled')"
                        :disabled="!selectedSearchAttributes.length || loading"
                        :append-icon="pagination.search ? 'close': ''"
                        prepend-inner-icon="search"
                        class="search"
                        ref="userSearch"
                        hide-details
                        solo
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="2"
                    class="text-center"
                >
                    <v-select
                        @change="selectedFilterAttributes = $event"
                        :items="filterAttributes"
                        :value="selectedFilterAttributes"
                        :label="$t('components.dataTableHeader.filterBy')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                        item-text="label"
                        item-value="value"
                        hide-details="auto"
                        multiple
                        solo
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                color="primary"
                                small
                            >
                                {{ data.item.label }}
                            </v-chip>
                        </template>
                    </v-select>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    class="text-center"
                >
                    <v-select
                        @change="selectedSearchAttributes = $event"
                        :items="searchAttributes"
                        :value="selectedSearchAttributes"
                        :label="$t('components.dataTableHeader.searchBy')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                        item-text="label"
                        item-value="value"
                        hide-details="auto"
                        multiple
                        solo
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                color="primary"
                                small
                            >
                                {{ data.item.label }}
                            </v-chip>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list-item
                                @click="toggle"
                                ripple
                            >
                                <v-list-item-action>
                                    <v-icon :color="selectedSearchAttributes.length > 0 ? 'primary darken-4' : ''">{{ icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t(`components.dataTableHeader.${selectedAllAttributes ? 'de' : ''}selectAll`) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.users.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="createDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeCreateDialog"
                                    />
                                    <v-container>
                                        <v-card class="mt-4 px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <v-row align="center">
                                                    <RequiredFields/>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.username"
                                                            @change="v => (editedEntity.username = v)"
                                                            :rules="[required, username, minimum, maximum]"
                                                            :label="$t('entities.users.attributes.username')"
                                                            :disabled="false"
                                                            prepend-icon="mdi-account-box"
                                                        ></v-text-field>
                                                        <span
                                                            v-if="false"
                                                            class="required-fields"
                                                        >
                                                            *
                                                        </span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            v-model="editedEntity.password"
                                                            @click:append="show = !show"
                                                            :rules="[required, minimumPassword]"
                                                            :type="show ? 'text' : 'password'"
                                                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                            :prepend-icon="show ? 'lock_open' : 'lock'"
                                                            :label="$t('login.password')"
                                                            counter
                                                            loading
                                                        >
                                                            <template v-slot:progress>
                                                                <v-progress-linear
                                                                    :value="progress"
                                                                    :color="color"
                                                                    height="5"
                                                                    absolute
                                                                    striped
                                                                ></v-progress-linear>
                                                            </template>
                                                        </v-text-field>
                                                        <span
                                                            v-if="editedIndex === -1"
                                                            class="required-fields"
                                                        >
                                                            *
                                                        </span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="pb-0"
                                                    >
                                                        <v-select
                                                            :value="editedEntity.roles"
                                                            @change="editedEntity.roles = $event"
                                                            :items="availableRoles"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            :label="$t(`entities.roles.title`)"
                                                            item-text="name"
                                                            item-value="id"
                                                            class="do-not-apply-transform"
                                                            prepend-inner-icon="mdi-account-circle"
                                                            return-object
                                                            multiple
                                                            solo
                                                        >
                                                            <template v-slot:selection="data">
                                                                <span v-if="data.index === 0">{{ $t(`entities.roles.title`) }}:</span>
                                                                <v-chip
                                                                    color="primary"
                                                                    small
                                                                >
                                                                    {{ data.item }}
                                                                </v-chip>
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="3"
                                                        class="pb-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.users.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="3"
                                                        class="pb-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.banned"
                                                            @change="v => (editedEntity.banned = v)"
                                                            :label="$t('entities.users.attributes.banned')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.card_id"
                                                            @change="v => (editedEntity.card_id = v)"
                                                            :label="$t('entities.users.attributes.cardId')"
                                                            :rules="[numeric, min]"
                                                            prepend-icon="mdi-card-account-details"
                                                            type="number"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <h3 class="mt-4 grey--text text--darken-1">
                                                            {{ $t('entities.users.identity') }}
                                                        </h3>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.identity.name"
                                                            @change="v => (editedEntity.identity.name = v)"
                                                            :label="$t('generalAttributes.fullName')"
                                                            prepend-icon="person"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-menu
                                                            v-model="menu"
                                                            :close-on-content-click="false"
                                                            ref="menu"
                                                            transition="scale-transition"
                                                            min-width="auto"
                                                            offset-y
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="editedEntity.identity.birthdate"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :label="$t('entities.users.attributes.birthdate')"
                                                                    prepend-icon="mdi-calendar"
                                                                    clearable
                                                                    readonly
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="editedEntity.identity.birthdate"
                                                                @change="saveDate"
                                                                :active-picker.sync="activePicker"
                                                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                                                min="1921-01-01"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.identity.address"
                                                            @change="v => (editedEntity.identity.address = v)"
                                                            :label="$t('generalAttributes.address')"
                                                            prepend-icon="house"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.identity.pidn"
                                                            @change="v => (editedEntity.identity.pidn = v)"
                                                            :label="$t('generalAttributes.pidn')"
                                                            prepend-icon="short_text"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <h3 class="mt-4 grey--text text--darken-1">
                                                            {{ $t('entities.users.contact') }}
                                                        </h3>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.email.address"
                                                            @change="v => (editedEntity.email.address = v)"
                                                            :label="$t('entities.users.attributes.email')"
                                                            :rules="[email]"
                                                            prepend-icon="email"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-row align="center">
                                                            <v-col
                                                                cols="6"
                                                                sm="5"
                                                                md="4"
                                                            >
                                                                <v-autocomplete
                                                                    v-model="editedEntity.phone.country_code"
                                                                    :items="countries"
                                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                                    class="do-not-apply-transform mt-2"
                                                                    prepend-inner-icon="phone"
                                                                    item-text="name"
                                                                    item-value="dialCode"
                                                                    solo
                                                                >
                                                                    <template v-slot:selection="data">
                                                                        <img
                                                                            :src="`https://flagcdn.com/w20/${data.item.iso2}.png`"
                                                                            width="15px"
                                                                            height="15px"
                                                                            class="mr-2"
                                                                        />
                                                                        +{{ data.item.dialCode }}
                                                                    </template>
                                                                    <template v-slot:item="data">
                                                                        <img
                                                                            :src="`https://flagcdn.com/w20/${data.item.iso2}.png`"
                                                                            width="15px"
                                                                            height="15px"
                                                                            class="mr-2"
                                                                        />
                                                                        {{ data.item.name }} +{{ data.item.dialCode }}
                                                                    </template>
                                                                </v-autocomplete>
                                                            </v-col>
                                                            <v-col
                                                                cols="6"
                                                                sm="7"
                                                                md="8"
                                                                class="pl-0"
                                                            >
                                                                <v-text-field
                                                                    :value="editedEntity.phone.national_number"
                                                                    @change="v => (editedEntity.phone.national_number = v)"
                                                                    :rules="[phoneNumber]"
                                                                    :label="$t('generalAttributes.phone')"
                                                                ></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeCreateDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.identity.name="{ item }">
                    {{ item.identity.name || '/' }}
                </template>
                <template v-slot:item.email="{ item }">
                    <template v-if="item.email.id">
                        <CopyTooltip :text="item.email.address"/>
                        <Status :status="item.email.status"/>
                    </template>
                    <template v-else>
                        /
                    </template>
                </template>
                <template v-slot:item.rank.info.name="{ item }">
                    <v-chip
                        color="primary"
                        small
                    >
                        {{ item.rank.info.name }}
                    </v-chip>
                </template>
                <template v-slot:item.action="{ item }">
                    <UserActions
                        :key="item.id"
                        :entity="item"
                        :initRoles="roles"
                        :userSessionInitial="userHasActiveSession(item.id)"
                        :viewButton=true
                        :editButton=true
                        :shopButton=true
                        :simulateSessionButton=true
                        :sessionButton=true
                        :deleteButton=true
                        @user-session-action="fetchSessions"
                        @user-deleted="fetchEntities"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
    </div>
</template>

<script>

import Vue from 'vue';
import {mapActions} from "vuex";
import Status from '@/components/Status.vue';
import NoData from '@/components/crud/NoData.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import CopyTooltip from '@/components/CopyTooltip.vue';
import RowsPerPage from '@/components/crud/RowsPerPage.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import User from '@/models/users/User';
import Session from '@/models/main/Session';
import convertDate from '@/mixins/convert-date';
import formatTime from '@/mixins/format-time';
import formatDate from '@/mixins/format-date';
import user from '@/mixins/crud/computed/user';
import isRole from '@/mixins/crud/computed/isRole';
import required from '@/mixins/rules/required';
import thousandSeparator from '@/mixins/thousand-separator';
import {axiosCatch} from '@/mixins/crud/methods/axios-catch.js';
import {paginationWatch} from '@/mixins/crud/watch/pagination.js';
import {deleteDialog} from '@/mixins/crud/watch/delete-dialog.js';
import UserActions from "@/components/UserActions.vue";
import {EventBus} from "@/eventBus";
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import COUNTRIES from '@/mixins/countries';
import Role from "@/models/users/Role";

export default Vue.extend({
    components: {
        UserActions,
        Status,
        NoData,
        GlobalButton,
        RowsPerPage,
        DataTableSkeletonLoader,
        DataTableFooter,
        RefreshButton,
        CopyTooltip,
        EditToolbar,
        ValidationErrors,
        RequiredFields,
        EditCardActions
    },

    mixins: [
        axiosCatch,
        paginationWatch,
        thousandSeparator,
        deleteDialog,
        user,
        isRole,
        convertDate,
        formatTime,
        formatDate,
        required,
    ],

    data: () => ({
        eventListenerAdded: false,
        initialLoad: true,
        loading: false,
        typing: false,
        loadingIndexes: [],
        show: false,
        menu: false,
        activePicker: null,
        entity: 'users',
        route: 'users',
        entities: [],
        sessions: [],
        selectedSearchAttributes: ['Username', 'Name', 'Card ID'],
        selectedFilterAttributes: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['id'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        roles: [],
        createDialog: false,
        saving: false,
        countries: COUNTRIES,
        editedIndex: -1,
        validationErrors: [],
        editedEntity: {
            id: null,
            username: '',
            password: '',
            card_id: '',
            availableCoins: 0,
            wanted_playtime_hours: 0,
            wanted_playtime_minutes: 0,
            roles: [],
            active: true,
            banned: false,
            keycloak_id: null,
            identity: {
                id: null,
                name: '',
                birthdate: null,
                address: '',
                pidn: '',
                image: '',
                status: '',
            },
            phone: {
                country_code: '',
                national_number: '',
            },
            email: {
                address: '',
            },
            wallet: {},
            subscriptions: [],
            settings: [],
            inventory: [],
            bonuses: {
                legend_bonus: 0,
                rank_bonus: 0,
                reward_bonus: 0,
                subscription_bonus: 0,
            },
            achievements: [],
            quests: [],
            events: [],
            simulatorOptions: () => ([]),
            selectedSimulatorOption: {},
        },
        defaultEntity: {
            id: null,
            username: '',
            password: '',
            card_id: '',
            availableCoins: 0,
            wanted_playtime_hours: 0,
            wanted_playtime_minutes: 0,
            roles: [],
            active: true,
            banned: false,
            keycloak_id: null,
            identity: {
                id: null,
                name: '',
                birthdate: null,
                address: '',
                pidn: '',
                image: '',
                status: '',
            },
            phone: {
                country_code: '',
                national_number: '',
            },
            email: {
                address: '',
            },
            wallet: {},
            subscriptions: [],
            settings: [],
            inventory: [],
            bonuses: {
                legend_bonus: 0,
                rank_bonus: 0,
                reward_bonus: 0,
                subscription_bonus: 0,
            },
            achievements: [],
            quests: [],
            events: [],
            simulatorOptions: () => ([]),
            selectedSimulatorOption: {},
        },
        defaultIdentity: {
            name: '',
            birthdate: null,
            address: '',
            pidn: '',
            image: ''
        },
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.fullName'), align: 'left', value: 'identity.name' },
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username' },
                { text: this.$t('entities.users.attributes.email'), align: 'left', value: 'email' },
                { text: this.$t('entities.users.attributes.rank'), align: 'left', value: 'rank.info.name' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        filterAttributes() {
            return [
                { label: this.$t('entities.users.attributes.active'), value: 'Active' },
                { label: this.$t('entities.users.attributes.banned'), value: 'Banned' },
                { label: this.$t('entities.users.verifiedIdentity'), value: 'Verified Identity' },
                { label: this.$t('entities.users.verifiedEmail'), value: 'Verified Email' },
                { label: this.$t('entities.users.verifiedPhone'), value: 'Verified Phone' },
            ]
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
                { label: this.$t('entities.users.attributes.name'), value: 'Name' },
                { label: this.$t('entities.users.attributes.email'), value: 'Email' },
                { label: this.$t('entities.users.attributes.phone'), value: 'Phone' },
                { label: this.$t('entities.users.attributes.cardId'), value: 'Card ID' },
                { label: this.$t('entities.users.attributes.rank'), value: 'Rank' },
                { label: this.$t('entities.roles.entity'), value: 'Role' },
            ]
        },
        selectedAllAttributes() {
            return this.selectedSearchAttributes.length === this.searchAttributes.length;
        },
        selectedSomeAttributes() {
            return this.selectedSearchAttributes.length > 0 && !this.selectedAllAttributes;
        },
        availableRoles() {
            if (this.isAdmin) {
                return this.roles.map(role => role.name)
            }
            if (this.isManager) {
                return this.roles.filter(role => role.name !== 'admin' && role.name !== 'manager').map(role => role.name);
            }
            return this.roles.filter(role => role.is_default).map(role => role.name);
        },
        icon() {
            if (this.selectedAllAttributes) {
                return 'mdi-close-box';
            }
            if (this.selectedSomeAttributes) {
                return 'mdi-minus-box';
            }
            return 'mdi-checkbox-blank-outline';
        },
        formTitle() {
            return (this.$t(`entities.${this.entity}.newEntity`));
        },
        numeric() {
            return v  => (!isNaN(v)) || this.$t('validation.numeric');
        },
        min() {
            return v => (v >= 0) || this.$t('validation.min', { amount: 0 });
        },
        minimumPassword() {
            return v => v && v.length >= 6 || this.$t('validation.minimum', { count: 6 });
        },
        minimumPasswordOrEmpty() {
            return v => !v || (v && v.length >= 6 || this.$t('validation.minimum', { count: 6 }));
        },
        minimum() {
            return v => v && v.length >= 3 || this.$t('validation.minimum', { count: 3 });
        },
        maximum() {
            return v => v && v.length <= 64 || this.$t('validation.maximum', { count: 64 });
        },
        email() {
            return v =>  !v || (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$t('validation.email'));
        },
        phoneNumber() {
            return v =>  !v || (/^[1-9]\d*$/.test(v) || this.$t('validation.phoneNumber'));
        },
        username() {
            return v => /^[\w.-]+$/.test(v) || this.$t('validation.username');
        },
        progress() {
            let strength = 0;

            if (this.editedEntity.password) {
                strength += /[A-Z]+/.test(this.editedEntity.password) && this.editedEntity.password.length > 1 ? 25 : 0;
                strength += /[a-z]+/.test(this.editedEntity.password) && this.editedEntity.password.length > 3 ? 25 : 0;
                strength += /[0-9]+/.test(this.editedEntity.password) && this.editedEntity.password.length > 5 ? 25 : 0;
                strength += /[\W]+/.test(this.editedEntity.password) && this.editedEntity.password.length > 7 ? 25 : 0;
            }

            return strength;
        },
        color() {
            return ['red', 'red', 'orange', 'yellow', 'green'][Math.floor(this.progress / 25)];
        }
    },

    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        selectedFilterAttributes() {
            this.fetchEntities();
        },
    },

    mounted() {
        this.refresh();
        this.fetchRoles();
    },

    created() {
        if (this.$route.params && this.$route.params.openDialog) {
            this.editDialog = true;
        }
        EventBus.$on('scanUserDialogClosed', this.refresh);
    },
    beforeDestroy() {
        EventBus.$off('scanUserDialogClosed');
    },

    methods: {

        ...mapActions(['showSnackbar']),

        openCreateDialog() {
            this.editedIndex = -1;
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.createDialog = true;
        },
        closeCreateDialog() {
          this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
          this.editedIndex = -1;
          this.createDialog = false;
        },

        async fetchEntities() {
            try {
                let append = this.setParams();
                // Append filters
                if (this.selectedFilterAttributes.length) {
                    const filter = this.selectedFilterAttributes.map(attribute => `${attribute.replaceAll(' ', '_').toLowerCase()}=true`);
                    append += '&' + filter.join('&');
                }
                this.loading = true;
                const response = await User.api.get(append);
                const data = response.data;
                this.entities = data.content.map((UserDTO) => {
                    const user = new User(UserDTO);
                    if (!user.identity) {
                        user.identity = {
                            name: '',
                            birthdate: null,
                            address: '',
                            pidn: '',
                            image: ''
                        }
                    }
                    if (!user.phone) {
                        user.phone = {
                            id: null,
                            country_code: '',
                            national_number: null,
                        }
                    }
                    if (!user.email) {
                        user.email = {
                            id: null,
                            address: '',
                            status: 'UNVERIFIED',
                        }
                    }
                    return user;
                });
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`Users API failed.`);
                console.log(e);
            } finally {
                const initial = this.initialLoad;
                this.initialLoad = false;
                this.loading = false;
                if (initial) {
                    if (window.innerWidth > 800) {
                        this.$nextTick(() => {
                            this.$refs.userSearch.$el.getElementsByTagName('input')[0].focus();
                        })
                    }
                } else {
                    this.$nextTick(() => {
                        this.$refs.userSearch.$el.getElementsByTagName('input')[0].focus();
                    })
                }
            }
        },
        async fetchSessions() {
            try {
                this.loadingSessions = true;
                const response = await Session.api.getByArena(this.$store.getters.arena.id, `active=true&size=100`);
                this.sessions = response.data.content;
            } catch (e) {
                console.warn(`Arena Sessions API failed.`);
                console.log(e);
            } finally {
                this.loadingSessions = false;
            }
        },

        async fetchRoles() {
            try {
                const response = await Role.api.get('size=100');
                this.roles = response.data.content;
            } catch (e) {
                console.warn('Roles API failed.');
                console.log(e);
            }
        },

         async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                const data = {
                    roles: this.editedEntity.roles,
                    active: this.editedEntity.active,
                    banned: this.editedEntity.banned,
                };

                if (this.$store.getters.arena) {
                    data.registered_in_arena = this.$store.getters.arena.id;
                }

                if (this.editedEntity.card_id) {
                    data.card_id = this.editedEntity.card_id;
                }

                // Password not required on update
                if (this.editedEntity.password && this.editedEntity.password.length) {
                    data.password = this.editedEntity.password
                }

                // Only on create
                data.username = this.editedEntity.username;

                if (this.editedEntity.identity.name || this.editedEntity.identity.pidn || this.editedEntity.identity.address || this.editedEntity.identity.birthdate) {
                    data.identity = {};
                }

                if (this.editedEntity.identity.name) {
                    data.identity.name = this.editedEntity.identity.name;
                }

                if (this.editedEntity.identity.pidn) {
                    data.identity.pidn = this.editedEntity.identity.pidn;
                }

                if (this.editedEntity.identity.address) {
                    data.identity.address = this.editedEntity.identity.address;
                }

                if (this.editedEntity.identity.birthdate) {
                    data.identity.birthdate = this.editedEntity.identity.birthdate;
                }

                // TODO => Image
                // if (this.editedEntity.identity.image) {
                //     data.identity.image = this.editedEntity.identity.image;
                // }

                if (this.editedEntity.phone.country_code && this.editedEntity.phone.national_number) {
                    data.phone = {
                        country_code: this.editedEntity.phone.country_code,
                        national_number: this.editedEntity.phone.national_number
                    };
                }

                if (this.editedEntity.email.address) {
                    data.email = this.editedEntity.email.address;
                }

                await User.api.create(data)
                    .then(() => {
                        if (this.pagination.search.length) {
                            this.pagination.search = '';
                        } else {
                            this.fetchEntities();
                        }
                        this.showSnackbar({color: 'success', message: this.$t('entities.users.notifications.creation')});
                        this.closeCreateDialog();
                    }).catch(error => {
                        this.axiosCatch(error);
                        });
            } catch (error) {
                console.warn(`User Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },

        saveDate(date) {
            this.$refs.menu.save(date);
        },
        userHasActiveSession(userId) {
            const session = this.sessions.find(s => s.user_id === userId);
            return session || null;
        },
        pcSession(userId) {
            const session = this.userHasActiveSession(userId);
            if (session) {
                return session.device_id !== "ps";
            } else {
                return false;
            }
        },
        toggle() {
            this.$nextTick(() => {
                 if (this.selectedAllAttributes) {
                    this.selectedSearchAttributes = [];
                } else {
                    this.selectedSearchAttributes = this.searchAttributes.map(({value}) => value);
                }
            })
        },
        refresh() {
            this.fetchEntities();
            if (this.$store.getters.arena) {
                this.fetchSessions();
            }
        }
    }
});

</script>

