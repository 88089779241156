import axios from '@/services/axios';
import {mapActions} from "vuex";

export const deleteEntity = {

    methods: {
        ...mapActions(['showSnackbar']),

        async deleteEntity() {
            try {
                this.deleting = true;
                await axios.delete(`${this.apiURI}/${this.route}/${this.toDeleteEntity.id}`)
                    .then(() => {
                        this.fetchEntities();
                        this.closeDeleteDialog();
                        this.showSnackbar({color:'success', message: this.$t(`entities.${this.entity}.notifications.deletion`)});
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`Delete API failed.`);
                console.log(error);
            } finally {
                this.deleting = false;
            }
        },
    },

};