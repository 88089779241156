<template>
    <div v-if="!$store.getters.arena">
        <v-row v-if="!$store.getters.arena">
            <v-col
                cols="12"
                class="pt-0"
            >
                <v-alert type="info">
                    <span class="mb-0 text--white">{{ $t('purchase.globalMessage') }}</span>
                </v-alert>
            </v-col>
        </v-row>
    </div>
    <div v-else>
        <v-row align="center">
            <v-col
                cols="6"
                class="py-0"
            >
                <v-row align="center">
                    <v-col
                        cols="auto"
                        class="py-0"
                    >
                        <v-select
                            v-model="selectedCashRegisterLabel"
                            @change="$store.commit('setSelectedCashRegisterLabel', $event)"
                            :items="cashRegisters"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :label="$t('entities.arenas.cashRegisters.title')"
                            :no-data-text="$t('purchase.noCashRegisters')"
                            :style="{ 'max-width' : cashRegisters.length ? '100px' : '' } "
                            item-text="label"
                            item-value="label"
                            class="do-not-apply-transform py-0"
                            solo
                        >
                            <template v-slot:item="data">
                                <v-icon
                                    color="primary"
                                    class="mr-2"
                                >
                                    mdi-cash-register
                                </v-icon>
                                {{ data.item.label }}
                            </template>
                            <template v-slot:selection="data">
                                <v-icon
                                    color="primary"
                                    class="mr-2"
                                >
                                    mdi-cash-register
                                </v-icon>
                                {{ data.item.label }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col
                        cols="auto"
                        class="py-0"
                        style="margin-bottom: 25px;"
                    >
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loadingItems"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                cols="6"
                class="text-right py-0"
            >
                <v-btn
                    @click="reset"
                    class="mb-6"
                    color="primary"
                >
                    <v-icon left>restart_alt</v-icon>
                    {{ $t('purchase.reset') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="7"
                class="pt-0 text-left"
            >
                <v-card
                    class="elevation-2"
                    style="min-height: 590px;"
                >
                    <v-stepper
                        v-model="step"
                        class="box-shadow-none"
                    >
                        <v-stepper-header>
                            <v-stepper-step
                                :complete="step > 1"
                                :editable="step > 1"
                                step="1"
                                edit-icon="done"
                                complete-icon="done"
                            >
                                {{ $t('miscellaneous.items') }}
                            </v-stepper-step>
                            <v-divider/>
                            <v-stepper-step
                                :complete="selectedItems.length > 0"
                                :editable="selectedItems.length > 0"
                                step="2"
                            >
                                {{ $t('miscellaneous.user') }}
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content
                                step="1"
                                class="px-0 pt-2 pb-0"
                            >
                                <v-row style="height: 515px;">
                                    <v-col class="px-0">
                                        <v-toolbar flat>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    class="text-center"
                                                >
                                                    <v-text-field
                                                        v-model="itemsPagination.search"
                                                        @click:append="clearSearch"
                                                        @keydown.native.escape="itemsPagination.search = ''"
                                                        :append-icon="itemsPagination.search ? 'close': ''"
                                                        :label="$t('entities.orders.searchItemByName')"
                                                        :disabled="initialLoad || (!itemsPagination.total && loadingItems)"
                                                        prepend-inner-icon="search"
                                                        class="search mb-1"
                                                        hide-details
                                                        solo
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <template v-slot:extension>
                                                <v-tabs
                                                    v-model="tab"
                                                    background-color="primary"
                                                    class="categories-header mx-1"
                                                    grow
                                                    dark
                                                >
                                                    <v-tab
                                                        v-for="(category, key) in categories"
                                                        :key="`tab-${key}`"
                                                        :href="`#${key}`"
                                                        class="font-weight-bold"
                                                    >
                                                        {{ key }}
                                                    </v-tab>
                                                </v-tabs>
                                            </template>
                                        </v-toolbar>
                                        <v-skeleton-loader
                                            v-if="initialLoad || (!itemsPagination.total && loadingItems)"
                                            :loading="initialLoad || (!itemsPagination.total && loadingItems)"
                                            type="table"
                                            height="210"
                                            class="px-4"
                                        />
                                        <v-tabs-items
                                            v-model="tab"
                                            class="mx-2"
                                            style="max-height: 340px; overflow-y: auto;"
                                        >
                                            <v-tab-item
                                                v-for="(items, key) in categories"
                                                :key="`tab-${key}`"
                                                :value="key"
                                            >
                                                <v-data-table
                                                    :headers="itemHeaders"
                                                    :items="items"
                                                    :loading="loadingItems"
                                                    :options.sync="itemOptions"
                                                    :server-items-length="itemsPagination.total"
                                                    :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                    :no-results-text="$t('entities.orders.noItemsFound')"
                                                    :loading-text="$t('entities.items.fetching')"
                                                    class="do-not-apply-hover mx-3"
                                                    dense
                                                    hide-default-footer
                                                >
                                                    <template v-slot:item.name="{ item }">
                                                        <template v-if="item.name !== 'Coins' && item.category === 'Coins'">
                                                            {{ item.name }} ({{ item.category_attributes ? item.category_attributes[0]['value'] : '' }} <CoinImage/>)
                                                        </template>
                                                        <template v-else>
                                                            {{ item.name }}
                                                            <template v-if="item.stock < 1">
                                                                <TextFieldInfoTooltip
                                                                    :text="$t('entities.items.stockInfo', { stock: item.stock })"
                                                                    slot="append"
                                                                />
                                                            </template>
                                                        </template>
                                                    </template>
                                                    <template v-slot:item.price="{ item }">
                                                        <template v-if="item.name === 'Coins'">
                                                            <b>{{ item.coins_per_currency }} <CoinImage/> / 1 {{ item.currency }}</b>
                                                        </template>
                                                        <template v-else>
                                                            <b>{{ format(item.price, true, item.currency) }}</b>
                                                        </template>
                                                    </template>
                                                    <template v-slot:item.quantity="{ item }">
                                                        <v-row
                                                            justify="center"
                                                            align="center"
                                                        >
                                                            <template v-if="item.name === 'Coins'">
                                                                <v-col
                                                                    cols="auto"
                                                                    class="py-0 px-2"
                                                                >
                                                                    <v-text-field
                                                                        v-model="coinsValue"
                                                                        v-money="money"
                                                                        ref="coins"
                                                                        style="width: 100px;"
                                                                    ></v-text-field>
                                                                </v-col>
                                                            </template>
                                                            <template v-else>
                                                                <v-col
                                                                    cols="auto"
                                                                    class="py-0 px-2"
                                                                >
                                                                    <v-btn
                                                                        @click="decrementQuantity(item)"
                                                                        :disabled="item.quantity < 1"
                                                                        color="primary"
                                                                        x-small
                                                                        rounded
                                                                    >
                                                                        <v-icon
                                                                            color="white"
                                                                            x-small
                                                                        >
                                                                            exposure_neg_1
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                                <v-col
                                                                    cols="auto"
                                                                    class="my-0 py-0 px-2"
                                                                >
                                                                    <v-text-field
                                                                        :value="item.quantity"
                                                                        v-on:keyup="handleChange(item, $event)"
                                                                        :key="item.type + '-' + item.id"
                                                                        type="number"
                                                                        class="quantity-input my-0 py-0"
                                                                    >
                                                                    </v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="auto"
                                                                    class="py-0 px-2"
                                                                >
                                                                    <v-btn
                                                                        @click="incrementQuantity(item)"
                                                                        color="primary"
                                                                        x-small
                                                                        rounded
                                                                    >
                                                                        <v-icon
                                                                            color="white"
                                                                            x-small
                                                                        >
                                                                            plus_one
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                            </template>
                                                            <v-col
                                                                cols="auto"
                                                                class="py-0 px-2"
                                                            >
                                                                <v-btn
                                                                    @click="removeItem(item)"
                                                                    :disabled="item.quantity < 1"
                                                                    color="error"
                                                                    x-small
                                                                    rounded
                                                                >
                                                                    <v-icon
                                                                        color="white"
                                                                        x-small
                                                                    >
                                                                        remove_shopping_cart
                                                                    </v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-data-table>
                                            </v-tab-item>
                                        </v-tabs-items>
                                        <v-row v-if="itemsPagination.total < 1 && !loadingItems">
                                            <v-col class="text-center my-4">
                                                <p class="mb-0">{{ $t('entities.orders.noItemsFound') }}</p>
                                            </v-col>
                                        </v-row>
                                        <v-footer
                                            color="primary"
                                            class="footer mx-5"
                                        >
                                            <v-row justify="center">
                                                <v-col
                                                    cols="12"
                                                    class="text-center white--text pt-4"
                                                >
                                                    <template v-if="loadingItems">
                                                        <v-skeleton-loader
                                                            :loading="loadingItems"
                                                            type="text"
                                                            width="150"
                                                            style="margin: 0 auto;"
                                                        />
                                                    </template>
                                                    <template v-else>
                                                        {{ $t('entities.orders.totalItems') }}: {{ itemsPagination.total }}
                                                    </template>
                                                </v-col>
                                            </v-row>
                                        </v-footer>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content
                                step="2"
                                class="px-0 pb-0"
                            >
                                <v-row align="center">
                                    <v-col cols="12">
                                        <v-row
                                            class="mb-2 px-4"
                                            align="center"
                                            justify="start"
                                        >
                                            <v-col
                                                cols="12"
                                                md="3"
                                                class="text-center"
                                            >
                                                <RowsPerPage :pagination="pagination"/>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="4"
                                                class="text-center"
                                            >
                                                <v-text-field
                                                    v-model="pagination.search"
                                                    @click:append="() => (pagination.search = '')"
                                                    @keydown.native.escape="pagination.search = ''"
                                                    :label="selectedSearchAttributes.length ? $t('components.dataTableHeader.search') : $t('components.dataTableHeader.searchDisabled')"
                                                    :disabled="!selectedSearchAttributes.length || loading"
                                                    :append-icon="pagination.search ? 'close': ''"
                                                    prepend-inner-icon="search"
                                                    class="search"
                                                    ref="userSearch"
                                                    hide-details
                                                    solo
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                md="5"
                                                class="text-center"
                                            >
                                                <v-select
                                                    @change="selectedSearchAttributes = $event"
                                                    :items="searchAttributes"
                                                    :value="selectedSearchAttributes"
                                                    :label="$t('components.dataTableHeader.searchBy')"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                    :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                    item-text="label"
                                                    item-value="value"
                                                    hide-details="auto"
                                                    multiple
                                                    solo
                                                >
                                                    <template v-slot:selection="data">
                                                        <v-chip
                                                            color="primary"
                                                            small
                                                        >
                                                            {{ data.item.label }}
                                                        </v-chip>
                                                    </template>
                                                    <template v-slot:prepend-item>
                                                        <v-list-item
                                                            @click="toggle"
                                                            ripple
                                                        >
                                                            <v-list-item-action>
                                                                <v-icon :color="selectedSearchAttributes.length > 0 ? 'primary darken-4' : ''">{{ icon }}</v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ $t(`components.dataTableHeader.${selectedAllAttributes ? 'de' : ''}selectAll`) }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider class="mt-2"></v-divider>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0"
                                    >
                                        <v-data-table
                                            @click:row="rowClick"
                                            :headers="userHeaders"
                                            :items="users"
                                            :loading="loading"
                                            :options.sync="options"
                                            :server-items-length="pagination.total"
                                            :items-per-page="pagination.per_page"
                                            :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                            :loading-text="$t('entities.users.fetching')"
                                            item-key="username"
                                            class="users-table do-not-apply-hover"
                                            dense
                                            show-select
                                            single-select
                                            hide-default-footer
                                        >
                                            <template v-slot:top>
                                                <v-toolbar flat>
                                                    <v-toolbar-title>{{ $t('entities.users.title') }}</v-toolbar-title>
                                                    <v-divider
                                                        class="mx-4"
                                                        inset
                                                        vertical
                                                    ></v-divider>
                                                    <small>{{ pagination.total }}</small>
                                                    <v-spacer></v-spacer>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:item.data-table-select="item">
                                                <div style="position: relative">
                                                    <v-checkbox
                                                        v-model="selectedUser"
                                                        :value="item.item.username"
                                                        class="select"
                                                    >
                                                    </v-checkbox>
                                                    <v-icon
                                                        v-if="selectedUser.username === item.item.username"
                                                        color="white"
                                                        size="14"
                                                        class="active-checkmark"
                                                    >
                                                        done
                                                    </v-icon>
                                                </div>
                                            </template>
                                            <template v-slot:item.identity.name="{ item }">
                                                <span>{{ item.identity && item.identity.name ? item.identity.name : '/' }}</span>
                                            </template>
                                            <template v-slot:item.email="{ item }">
                                                <span>{{ item.email ? item.email.address : '/' }}</span>
                                            </template>
                                            <template v-slot:no-data>
                                                <template v-if="pagination.search.length && searchingUser">
                                                    {{ $t('entities.users.fetching') }}
                                                </template>
                                                <template v-else-if="pagination.search.length && !users.length">
                                                    {{ $t('components.noData.noEntriesFound') }}
                                                </template>
                                                <template v-else>
                                                    {{ $t('purchase.searchUser') }}
                                                </template>
                                            </template>
                                        </v-data-table>
                                        <v-row v-if="users.length">
                                            <v-col
                                                cols="12"
                                                class="py-0"
                                            >
                                                <DataTableFooter :pagination="pagination"/>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="px-8 pb-6"
                                    >
                                        <v-expansion-panels v-model="company.r1">
                                            <v-expansion-panel>
                                                <v-expansion-panel-header
                                                    class="px-6"
                                                    disable-icon-rotate
                                                >
                                                    {{ $t('purchase.issueForCompany') }}
                                                    <template v-slot:actions>
                                                        <template v-if="company.r1 === 0">
                                                            <v-icon color="success">
                                                                check_circle
                                                            </v-icon>
                                                        </template>
                                                        <template v-else>
                                                            <v-icon color="error">
                                                                cancel
                                                            </v-icon>
                                                        </template>
                                                    </template>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content class="px-6 pb-2">
                                                    <v-row align="center">
                                                        <v-col
                                                            cols="12"
                                                            sm="6"
                                                            class="position-relative py-0"
                                                        >
                                                            <v-text-field
                                                                v-model="company.name"
                                                                :rules="company.r1 === 0 ? [required] : []"
                                                                :label="$t('entities.users.attributes.name')"
                                                                prepend-icon="business"
                                                            ></v-text-field>
                                                            <span class="required-fields">*</span>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            sm="6"
                                                            class="position-relative py-0"
                                                        >
                                                            <v-text-field
                                                                v-model="company.pidn"
                                                                :rules="company.r1 === 0 ? [required] : []"
                                                                :label="$t('generalAttributes.pidn')"
                                                                prepend-icon="short_text"
                                                            ></v-text-field>
                                                            <span class="required-fields">*</span>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            class="position-relative pt-0"
                                                        >
                                                            <v-text-field
                                                                v-model="company.address"
                                                                :rules="company.r1 === 0 ? [required] : []"
                                                                :label="$t('generalAttributes.address')"
                                                                prepend-icon="business"
                                                            ></v-text-field>
                                                            <span class="required-fields">*</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                sm="5"
                class="text-left"
            >
                <v-card
                    class="elevation-2"
                    style="height: 590px;"
                >
                    <v-row class="px-6">
                        <v-col
                            cols="12"
                            class="text-left pb-0"
                            style="height: 132px;"
                        >
                            <h3 class="grey--text text--darken-1">
                                {{ $t('entities.users.entity') }}
                            </h3>
                            <template v-if="selectedUser !== -1">
                                <span>{{ $t('entities.users.attributes.username') }}: <b>{{ selectedUser.username }}</b>
                                    <v-chip
                                        color="primary"
                                        class="font-weight-bold ml-2"
                                        text-color="white"
                                        small
                                    >
                                        {{ selectedUser.rank?.info.name }}
                                    </v-chip>
                                </span>
                                <br>
                                <span>{{ $t('entities.users.attributes.name') }}: <b>{{ selectedUser.identity ? (selectedUser.identity.name || '/') : '/' }}</b></span>
                                <br>
                                <span>{{ $t('entities.users.attributes.email') }}: <b>{{ selectedUser.email && selectedUser.email.address ? selectedUser.email.address : '/' }}</b></span>
                                <template v-if="selectedUser.wallet">
                                    <br>
                                    <span>{{ $t('generalAttributes.coins') }}: <b>{{ addThousandSeparator(selectedUser.wallet.balance) }} <CoinImage/></b></span>
                                </template>
                            </template>
                            <template v-else>
                                <i
                                    v-if="coinsTimeTicketSubscriptionOrPassAddedAndUserNotSelected"
                                    class="red--text text--lighten-1"
                                >
                                    {{ $t('purchase.userNotSelectedButRequired') }}
                                </i>
                                <i
                                    v-else
                                    class="grey--text"
                                >
                                    {{ $t('purchase.userNotSelected') }}
                                </i>
                            </template>
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-left pb-0"
                        >
                            <h3 class="grey--text text--darken-1">
                                {{ $t('entities.items.title') }}
                            </h3>
                            <v-row align="center">
                                <v-col style="height: 240px; overflow-y: auto;">
                                    <template v-if="selectedItems.length">
                                        <v-simple-table
                                            class="do-not-apply-hover"
                                            dense
                                        >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th
                                                            class="text-left"
                                                            style="padding-left: 0;"
                                                        >
                                                            {{ $t('generalAttributes.name') }}
                                                        </th>
                                                        <th class="text-left">
                                                            {{ $t('generalAttributes.quantity') }}
                                                        </th>
                                                        <th class="text-left">
                                                            {{ $t('generalAttributes.price') }}
                                                        </th>
                                                        <th class="text-right">
                                                            {{ $t('entities.invoices.printData.amount') }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="item in selectedItems"
                                                        :key="item.name"
                                                    >
                                                        <td
                                                            class="pl-0"
                                                            style="border-left: 0;"
                                                        >
                                                            <template v-if="item.name !== 'Coins' && item.category === 'Coins'">
                                                                {{ item.name }} ({{ item.category_attributes ? item.category_attributes[0]['value'] : '' }} <CoinImage/>)
                                                            </template>
                                                            <template v-else>
                                                                {{ item.name }}
                                                            </template>
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            {{ addThousandSeparator(item.quantity) }}
                                                            <template v-if="item.name === 'Coins'">
                                                                <CoinImage/>
                                                            </template>
                                                        </td>
                                                        <td class="font-weight-bold">
                                                            <template v-if="item.name === 'Coins'">
                                                                {{ item.coins_per_currency }} <CoinImage/> / 1 {{ item.currency }}
                                                            </template>
                                                            <template v-else>
                                                                {{ format(item.price, true, item.currency) }}
                                                            </template>
                                                        </td>
                                                        <td class="text-right font-weight-bold">{{ format(item.price * item.quantity, false) }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </template>
                                    <template v-else>
                                        <i class="grey--text">{{ $t('purchase.itemsNotAdded') }}</i>
                                    </template>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0 pl-3"
                                    style="height: 40px;"
                                >
                                    <v-simple-table
                                        v-if="selectedItems.length"
                                        class="do-not-apply-hover"
                                        dense
                                    >
                                        <template v-slot:default>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        class="pl-0"
                                                        style="border-left: 0;"
                                                    >
                                                        {{ $t('entities.invoices.printData.total') }}
                                                    </td>
                                                    <td class="text-right font-weight-bold">{{ format(totalPrice, true, $store.getters.arena.currency) }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-left py-0"
                        >
                            <h3 class="grey--text text--darken-1">
                                {{ $t('miscellaneous.paymentMethod') }}
                            </h3>
                            <v-row align="center">
                                <template v-if="paymentMethods.length">
                                    <v-col cols="12">
                                        <v-chip-group
                                            v-model="selectedPaymentMethod"
                                            mandatory
                                        >
                                            <v-chip
                                                v-for="paymentMethod in paymentMethods"
                                                :key="paymentMethod.id"
                                                :value="paymentMethod"
                                                :filter-icon="paymentMethodIcon(selectedPaymentMethod.name)"
                                                active-class="font-weight-bold primary px-6"
                                                filter
                                            >
                                                {{ $t(`miscellaneous.paymentMethods.${titleToCamelCase(paymentMethod.name)}`) }}
                                            </v-chip>
                                        </v-chip-group>
                                    </v-col>
                                </template>
                                <template v-else>
                                    <v-col cols="auto">
                                        <p class="mb-0">
                                            {{ $t('purchase.noPaymentMethods') }}
                                        </p>
                                        <p class="mb-0">
                                            {{ $t('purchase.noPaymentMethodsMessage') }}
                                        </p>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center pt-6"
                        >
                            <v-btn
                                @click="createInvoice"
                                :disabled="cannotFinish"
                                :loading="creatingInvoice"
                                color="primary"
                                class="px-8"
                                height="50"
                                rounded
                            >
                                <span>{{ creatingInvoice ? $t('purchase.creatingInvoice') : $t('purchase.finish') }}</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="4"
                class="pt-6 text-left"
            >
                <v-card
                    class="elevation-2"
                    style="min-height: 280px;"
                >
                    <v-row class="px-6 ">
                        <v-col
                            cols="12"
                            class="text-left pb-0"
                        >
                            <v-row class="pt-4">
                                <h3 class="grey--text text--darken-1">
                                    {{ $t('purchase.usersTopPurchases') }}
                                </h3>
                            </v-row>
                            <v-row align="center">
                                <v-col style="max-height: 240px; overflow-y: auto;" class="pt-0">
                                    <v-simple-table
                                        v-if="!loadingUserMostPurchased"
                                        class="do-not-apply-hover"
                                        dense
                                        hide-default-footer
                                        show-select="false"
                                    >
                                        <thead class="user-sales-data">
                                            <tr>
                                                <th
                                                    class="text-left"
                                                    style="padding-left: 0; width: auto;"
                                                >
                                                    {{ $t('generalAttributes.name') }}
                                                </th>
                                                <th class="text-right" style="width: 30%;">
                                                    {{ $t('generalAttributes.quantity') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody  class="user-sales-body">
                                            <tr v-if="userMostPurchased.length === 0">
                                                <td colspan="2" class="text-center grey--text text--darken-1">
                                                    {{ $t('purchase.noPurchases') }}
                                                </td>
                                            </tr>
                                            <tr
                                                v-for="item in userMostPurchased"
                                                :key="item.item_name"
                                            >
                                                <td
                                                    class="pl-0"
                                                    style="border-left: 0; width: auto;"
                                                >
                                                    {{ item.item_name }}
                                                </td>
                                                <td class="font-weight-bold text-right" style="width: 30%;">
                                                    {{ addThousandSeparator(item.total_sold) }}x
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                    <v-progress-linear
                                        v-if="loadingUserMostPurchased"
                                        indeterminate
                                        color="primary"
                                    ></v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                sm="3"
                class="pt-6 text-left"
            >
                <v-card
                    class="elevation-2"
                    style="min-height: 280px;"
                >
                    <v-row class="px-6">
                        <v-col
                            cols="12"
                            class="text-left pb-0"
                        >
                            <v-row class="pt-4">
                                <h3 class="grey--text text--darken-1">
                                    {{ $t('purchase.usersTopGames') }}
                                </h3>
                            </v-row>
                            <v-row align="center">
                                <v-col style="max-height: 240px; overflow-y: auto;" class="pt-0">
                                    <v-simple-table
                                        v-if="!loadingUserMostPlayedGames"
                                        class="do-not-apply-hover"
                                        dense
                                        hide-default-footer
                                        show-select="false"
                                    >
                                        <thead class="user-sales-data">
                                            <tr>
                                                <th class="text-left" style="padding-left: 0;  width: auto;">
                                                    {{ $t('generalAttributes.name') }}
                                                </th>
                                                <th class="text-right" style="width: 30%;">
                                                    {{ $t('purchase.playtime') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="user-sales-body">
                                            <tr v-if="userMostGames.length === 0">
                                                <td colspan="2" class="text-center grey--text text--darken-1">
                                                    {{ $t('purchase.noGamesPlayed') }}
                                                </td>
                                            </tr>
                                            <tr
                                                v-for="item in userMostGames"
                                                :key="item.game_id"
                                            >
                                                <td
                                                    class="pl-0"
                                                    style="border-left: 0;"
                                                >
                                                    {{ item.name }}
                                                </td>
                                                <td class="font-weight-bold text-right">
                                                    {{ addThousandSeparator(item.total_playtime_h.toFixed(2)) }} h
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                    <v-progress-linear
                                        v-if="loadingUserMostPlayedGames"
                                        indeterminate
                                        color="primary"
                                    ></v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                sm="5"
                class="pt-6 text-left"
            >
                <v-card
                    class="elevation-2"
                    style="min-height: 280px;"
                >
                    <v-row class="px-6">
                        <v-col
                            cols="12"
                            class="text-left pb-0"
                        >
                            <v-row class="pt-4">
                                <h3 class="grey--text text--darken-1 pb-2">
                                    {{ $t('purchase.usersSubscriptions') }}
                                </h3>
                            </v-row>
                            <v-row align="center">
                                <v-col style="max-height: 240px" class="pt-0">
                                    <v-simple-table
                                        v-if="!loadingUserSubscriptions"
                                        class="do-not-apply-hover"
                                        dense
                                        hide-default-footer
                                        show-select="false"
                                    >
                                        <thead class="user-sales-data">
                                            <tr>
                                                <th class="text-left" style="padding-left: 0; width: 25%;">
                                                    {{ $t('generalAttributes.name') }}
                                                </th>
                                                <th class="text-left" style="padding-left: 0; width: 11%;">
                                                    {{ $t('entities.ranks.attributes.bonus') }}
                                                </th>
                                                <th class="text-left" style="padding-left: 0; width: 32%;">
                                                    {{ $t('miscellaneous.createdAt') }}
                                                </th>
                                                <th class="text-left" style="padding-left: 0; width: 32%;">
                                                    {{ $t('entities.carts.attributes.expiresAt') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="user-sales-body">
                                            <tr v-if="userSubscriptions.length === 0">
                                                <td colspan="2" class="text-center grey--text text--darken-1">
                                                    {{ $t('purchase.noSubscriptions') }}
                                                </td>
                                            </tr>
                                            <tr
                                                v-for="item in userSubscriptions"
                                                :key="item.id"
                                            >
                                                <td class="text-left" style="padding-left: 0; width: 25%;">
                                                    {{ item.name }}
                                                </td>
                                                <td class="text-left" style="padding-left: 0; width: 11%;">
                                                    {{ item.bonus }}
                                                </td>
                                                <td class="text-left" style="padding-left: 0; width: 32%;">
                                                    {{ fromUtcDateTimeToCurrent(item.created_at, false, true) }}
                                                </td>
                                                <td class="text-left" style="padding-left: 0; width: 32%;">
                                                    {{ fromUtcDateTimeToCurrent(item.expires_at, false, true) }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                    <v-progress-linear
                                        v-if="loadingUserSubscriptions"
                                        indeterminate
                                        color="primary"
                                    ></v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <Print :invoiceData="invoiceData"/>
    </div>
</template>

<script>

import Vue from 'vue';
import {mapActions} from "vuex";
import Print from '@/views/shop/Print.vue';
import TextFieldInfoTooltip from '@/components/TextFieldInfoTooltip';
import CoinImage from '@/components/CoinImage.vue';
import RowsPerPage from '@/components/crud/RowsPerPage.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import User from '@/models/users/User';
import Item from '@/models/shop/Item';
import Invoice from '@/models/shop/Invoice';

import user from '@/mixins/crud/computed/user';
import currency from '@/mixins/currency';
import convertCase from '@/mixins/convert-case';
import required from '@/mixins/rules/required';
import thousandSeparator from '@/mixins/thousand-separator';
import { VMoney } from 'v-money';
import { printInvoice } from '@/mixins/print-invoice.js';
import KpiUserApi from "@/services/kpi/KpiUserApi";
import GamesApi from "@/services/configuration/GamesApi";
import UsersApi from "@/services/users/UsersApi";
import Subscription from "@/models/users/Subscription";
import convertDate from "@/mixins/convert-date";

export default Vue.extend({
    components: {
        TextFieldInfoTooltip,
        RowsPerPage,
        DataTableFooter,
        CoinImage,
        RefreshButton,
        Print,
    },

    directives: { money : VMoney },

    mixins: [
        user,
        currency,
        convertCase,
        convertDate,
        printInvoice,
        thousandSeparator,
        required,
    ],

    data: () => ({
        debounce: null,
        initialLoad: true,
        loading: false,
        loadingItems: false,
        searchingUser: false,
        creatingInvoice: false,
        step: 1,
        users: [],
        options: {},
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 5,
            to: 0,
            total: 0
        },
        selectedSearchAttributes: ['Username', 'Name', 'Card ID'],
        tab: null,
        categories: [],
        itemOptions: {},
        itemsPagination: {
            search: '',
            to: 0,
            total: 0
        },
        coinsValue: '',
        paymentMethods: [],
        selectedCashRegisterLabel: null,
        selectedUser: -1,
        selectedItems: [],
        userMostPurchased: [],
        loadingUserMostPurchased: false,
        userMostGames: [],
        loadingUserMostPlayedGames: false,
        userSubscriptions: [],
        loadingUserSubscriptions: false,
        selectedPaymentMethod: 0,
        company: {
            r1: undefined,
            name: '',
            pidn: '',
            address: '',
        },
    }),

    computed: {
        itemHeaders() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name', sortable: false },
                { text: this.$t('generalAttributes.price'), align: 'left', value: 'price', sortable: false, width: '135px' },
                { text: this.$t('generalAttributes.quantity'), align: 'center', value: 'quantity', sortable: false, width: '250px' }
            ];
        },
        userHeaders() {
            return [
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username' },
                { text: this.$t('entities.users.attributes.name'), align: 'left', value: 'identity.name' },
                { text: this.$t('entities.users.attributes.email'), value: 'email' }
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
                { label: this.$t('entities.users.attributes.name'), value: 'Name' },
                { label: this.$t('entities.users.attributes.email'), value: 'Email' },
                { label: this.$t('entities.users.attributes.phone'), value: 'Phone' },
                { label: this.$t('entities.users.attributes.cardId'), value: 'Card ID' },
            ]
        },
        totalPrice() {
            let total = 0;
            for (const item in this.selectedItems) {
                total += (this.selectedItems[item].quantity * this.selectedItems[item].price);
            }
            return total;
        },
        arena() {
            return this.$store.getters.arena;
        },
        cashRegisters() {
            return this.arena ? this.arena.cashRegisters : [];
        },
        getSearchAttributes() {
            let searchAttributes = '';
            for (const i in this.selectedSearchAttributes) {
                searchAttributes += this.selectedSearchAttributes[i];
                if (parseInt(i) !== this.selectedSearchAttributes.length - 1) {
                    searchAttributes += ', ';
                }
            }
            return searchAttributes === '' ? 'results' : searchAttributes;
        },
        companySelectedAndEmpty() {
            return this.company.r1 === 0 && (!this.company.name.length || !this.company.pidn.length || !this.company.address.length);
        },
        cannotFinish() {
            return !this.selectedPaymentMethod || !this.selectedItems.length
                || !this.selectedCashRegisterLabel || this.companySelectedAndEmpty
                || this.coinsTimeTicketSubscriptionOrPassAddedAndUserNotSelected || this.creatingInvoice;
        },
        coinsTimeTicketSubscriptionOrPassAddedAndUserNotSelected() {
            const coinsTimeTicketSubscriptionsOrPassAdded = this.selectedItems.filter(item => item.category === 'Coins' || item.category === 'Subscriptions' || item.category === 'Time Tickets' || item.category === 'Passes').length > 0;
            return coinsTimeTicketSubscriptionsOrPassAdded ? coinsTimeTicketSubscriptionsOrPassAdded && this.selectedUser === -1 : false;
        },
        selectedAllAttributes() {
            return this.selectedSearchAttributes.length === this.searchAttributes.length
        },
        selectedSomeAttributes() {
            return this.selectedSearchAttributes.length > 0 && !this.selectedAllAttributes
        },
        icon() {
            if (this.selectedAllAttributes) return 'mdi-close-box'
            if (this.selectedSomeAttributes) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        money() {
            return {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false,
                suffix: this.$store.getters.arena ? ` ${this.$store.getters.arena.currency}` : '',
            }
        },
    },

    watch: {
        selectedSearchAttributes() {
            this.pagination.search = '';
        },
        tab(val) {
            if (typeof val !== 'undefined') {
                const categoriesLength = this.categories[val].length;
                this.itemsPagination.to = (categoriesLength >= 10 ? 10 : categoriesLength);
                this.itemsPagination.total = categoriesLength;
            } else {
                this.itemsPagination.to = 0;
                this.itemsPagination.total = 0;
            }
        },
        coinsValue(val) {
            this.updateCoins(val);
        },
        step(val) {
            if (val === '2') {
                this.$nextTick(() => {
                    this.$refs.userSearch.$el.getElementsByTagName('input')[0].focus();
                })
            }
        },

        selectedUser(newVal) {
            if (newVal !== -1 && newVal.id !== undefined && newVal.id !== null) {
                this.fetchUserUpsellData();
            } else {
                this.userMostPurchased = [];
            }
        },

        'itemsPagination.search': function() {
            if (this.debounce) {
                clearTimeout(this.debounce)
            }
            this.debounce = setTimeout(() => {
                this.fetchItems(`&name=${this.itemsPagination.search}`);
            }, 400)
        },
        'pagination.per_page': function() {
            if (this.pagination.current_page !== 1) {
                this.pagination.current_page = 1;
            } else {
                this.fetchEntities(`&size=${this.pagination.per_page}&${this.formatSelectedSearchAttributes()}&page=${this.pagination.current_page}`);
            }
        },
        'pagination.search': function(newVal) {
            this.searchingUser = true;
            if (this.debounce) {
                clearTimeout(this.debounce)
            }

            if (newVal.includes('FFCARDID:')) {
                this.pagination.search = newVal.replace('FFCARDID:', '').trim();
            }

            this.debounce = setTimeout(() => {
                this.users = [];
                this.pagination.current_page = 1;
                this.pagination.from = 1;
                this.pagination.last_page = 0;
                this.pagination.path = '';
                this.pagination.per_page = 5;
                this.pagination.to = 0;
                this.pagination.total = 0;
                if (this.pagination.search) {
                    if (this.pagination.current_page !== 1) {
                        this.pagination.current_page = 1;
                    } else {
                        this.fetchEntities(`&size=${this.pagination.per_page}&${this.formatSelectedSearchAttributes()}&page=${this.pagination.current_page}`);
                    }
                }
                this.searchingUser = false;
            }, 1500);
        },
        'pagination.current_page': function() {
            if (this.pagination.search) {
                if (this.options.sortBy.length) {
                    const sortBy = this.options.sortBy[0];
                    const sortDirection = this.options.sortDesc[0] ? 'DESC' : 'ASC';
                    this.fetchEntities(`&size=${this.pagination.per_page}&${this.formatSelectedSearchAttributes()}&page=${this.pagination.current_page}&sort=${sortBy},${sortDirection}`);
                } else {
                    this.fetchEntities(`&size=${this.pagination.per_page}&${this.formatSelectedSearchAttributes()}&page=${this.pagination.current_page}`);
                }
            }
        },
        'options.sortBy': function () {
            this.sort()
        },
        'options.sortDesc': function () {
            this.sort();
        },
    },

    mounted() {
        if (this.arena) {
            this.refresh();
        }
    },

    created() {
        // Set first cash register
        this.selectedCashRegisterLabel = this.$store.getters.selectedCashRegisterLabel;
        if (!this.selectedCashRegisterLabel && this.cashRegisters.length) {
            this.$store.commit('setSelectedCashRegisterLabel', this.cashRegisters[0].label);
            this.selectedCashRegisterLabel = this.$store.getters.selectedCashRegisterLabel;
        }

        // Set first payment method
        this.paymentMethods = this.arena && this.arena.paymentMethods ? this.arena.paymentMethods : []
        if (this.paymentMethods.length) {
            this.selectedPaymentMethod = this.paymentMethods[0];
        }

        // Set user if sent
        if (this.$route.params && this.$route.params.user) {
            this.selectedUser = JSON.parse(this.$route.params.user);
        }
    },

    methods: {

        ...mapActions(['showSnackbar']),

        rowClick: function (item, row) {
            if (this.selectedUser.username === item.username) {
                row.select(false);
                this.selectedUser = -1;
            } else {
                row.select(true);
                this.selectedUser = item;
            }
        },
        async fetchEntities(append = '') {
            try {
                this.loading = true;
                const response = await User.api.get(append);
                const data = response.data;
                this.users = data.content.map(UserDTO => new User(UserDTO));
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn('Users API failed.');
                console.log(e);
            } finally {
                this.loading = false;
                this.$nextTick(() => {
                    this.$refs.userSearch.$el.getElementsByTagName('input')[0].focus();
                })
            }
        },
        async fetchItems(append = '') {
            try {
                this.loadingItems = true;
                const response = await Item.api.get('/purchase?', `arena_id=${this.arena.id}${append}`);
                this.categories = response.data;
                const obj = Object.values(this.categories)[0];

                if (typeof obj !== 'undefined') {
                    const categoriesLength = obj.length;
                    this.itemsPagination.to = (categoriesLength >= 10 ? 10 : categoriesLength);
                    this.itemsPagination.total = categoriesLength;
                }

                for (const i in this.categories) {
                    // Adding quantity attribute
                    for (const j in this.categories[i]) {
                        this.$set(this.categories[i][j], 'quantity', 0);
                        for (const index in this.selectedItems) {
                            if (this.selectedItems[index].id === this.categories[i][j].id) {
                                this.$set(this.categories[i][j], 'quantity', this.selectedItems[index].quantity);
                            }
                        }
                    }
                }

                if (!this.itemsPagination.search.length && 'Coins' in this.categories) {
                    this.tab = 'Coins';
                }
            } catch (e) {
                console.warn('Variants API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loadingItems = false;
            }
        },
        async createInvoice() {
            try {
                this.creatingInvoice = true;

                const data = {
                    by_user: {
                        id: this.user.id,
                        name: this.user.name,
                        email: this.user.email,
                        username: this.user.username,
                        pidn: this.user.pidn
                    },
                    arena_id: this.arena.id,
                    cash_register_label: this.selectedCashRegisterLabel,
                    payment_method: this.selectedPaymentMethod,
                    variants: this.selectedItems,
                };

                let user = null;

                if (this.selectedUser !== -1) {
                    user = {
                        id: this.selectedUser.id,
                        name: this.selectedUser.identity ? this.selectedUser.identity.name : null,
                        email: this.selectedUser.email ? this.selectedUser.email.address : null,
                        username: this.selectedUser.username,
                        balance: this.selectedUser.wallet ? this.selectedUser.wallet.balance : null,
                    }
                }

                if (this.company.r1 !== undefined) {
                    if (user !== null) {
                        user.company = {
                            name: this.company.name,
                            pidn: this.company.pidn,
                            address: this.company.address,
                        }
                    } else {
                        user = {
                            company: {
                                name: this.company.name,
                                pidn: this.company.pidn,
                                address: this.company.address,
                            }
                        }
                    }
                }

                if (user !== null) {
                    data.user = user;
                }

                await Invoice.api.create(data)
                    .then((resp) => {
                        const invoiceData = resp.data.invoice;

                        if (this.totalPrice === 0) {
                            this.reset();
                            this.showSnackbar({color:'success', message: this.$t('entities.invoices.notifications.transaction')});
                            return;
                        }

                        this.$set(this, 'invoiceData', {
                            arena: {
                                invoice_settings: invoiceData.arena.invoice_settings,
                                company: {
                                    pidn: invoiceData.arena.company.pidn,
                                    name: invoiceData.arena.company.name,
                                    address: invoiceData.arena.company.address,
                                    image: invoiceData.arena.company.image,
                                    fiscalData: {
                                        inTaxSystem: invoiceData.arena.company.fiscal_data.inTaxSystem,
                                    },
                                    country: {
                                        currency: invoiceData.arena.company.country.currency,
                                        locale: invoiceData.arena.company.country.locale
                                    }
                                },
                            },
                            invoice: {
                                label: invoiceData.label,
                                cart: {
                                    gross: invoiceData.cart.gross,
                                    net: invoiceData.cart.net,
                                    items: invoiceData.cart.items,
                                },
                                taxes: invoiceData.taxes,
                                fiscalData: invoiceData.fiscal_data,
                                byUser: {
                                    name: invoiceData.by_user.name
                                },
                                user: invoiceData.user,
                                paymentMethod: {
                                    name: invoiceData.payment_method.name
                                },
                                created_at: invoiceData.created_at
                            }
                        });

                        this.reset();

                        this.$nextTick(() => {
                            if (this.arena.automaticallyOpenPrintUponInvoiceCreation) {
                                this.printInvoice();
                            } else {
                                this.showSnackbar({color:'success', message: this.$t('entities.invoices.notifications.creation')});
                            }
                        });
                    });
            } catch (error) {
                let message = error.response.data.message;
                const errors = [];
                let iconAndColor = 'error';

                // Validation errors
                if (error.response.status === 422) {
                    iconAndColor = 'warning';
                    for (const i in error.response.data.errors) {
                        errors.push(`${i}:${error.response.data.errors[i]}`)
                    }
                    message += ' The following rules failed: ' + errors.join(', ');
                }

                // Unexpected errors
                if (error.response.status === 500) {
                    if (typeof error.response.data.errors !== 'undefined' ) {
                        for (const i in error.response.data.errors) {
                            errors.push(error.response.data.errors[i])
                        }
                        message += ' The following errors occurred: ' + errors.join(', ');
                    }
                }

                this.showSnackbar({color: iconAndColor, message: message, timeout: 10000, icon: iconAndColor});
                this.reset();
            } finally {
                this.creatingInvoice = false;
            }
        },
        async fetchUserUpsellData(){
            if (this.selectedUser === -1 ){
                return;
            }
            Promise.all([
                this.fetchSelectedUserMostPurchasedItems(),
                this.fetchSelectedUserMostPlayedGames(),
                this.fetchUsersSubscriptions()
            ]);
        },
        async fetchSelectedUserMostPurchasedItems() {
            this.loadingUserMostPurchased = true;
            try {
                const endDate = new Date();
                const startDate = new Date();
                startDate.setFullYear(endDate.getFullYear() - 1);
                const response = await KpiUserApi.getMostSoldToUser(this.selectedUser.id, startDate, endDate);

                this.userMostPurchased = response.data;
            } catch (e) {
                console.warn('KPI User API failed.');
                console.log(e);
            }finally {
                this.loadingUserMostPurchased = false;
            }
        },

        async fetchSelectedUserMostPlayedGames() {
            this.loadingUserMostPlayedGames = true;
            try {
                const endDate = new Date();
                const startDate = new Date();
                startDate.setFullYear(endDate.getFullYear() - 1);
                const response = await KpiUserApi.getMostPlayedByUser(this.selectedUser.id, startDate, endDate);
                this.userMostGames = response.data;

                const searchParams = "game_ids=" + this.userMostGames.map(game => game.game_id).join('&game_ids=');
                const gamesResponse = await GamesApi.search(searchParams);
                const games = gamesResponse.data.data;
                this.userMostGames.forEach(game => {
                    const gameData = games.find(g => g.id === game.game_id);
                    if (gameData) {
                        game.name = gameData.name;
                    }
                });
            } catch (e) {
                console.warn('KPI User API failed.');
                console.log(e);
            }finally {
                this.loadingUserMostPlayedGames = false;
            }
        },

        async fetchUsersSubscriptions() {
            this.loadingUserSubscriptions = true;
            try {
                const response = await UsersApi.getSubscriptions(this.selectedUser.id);
                const oneYearAgo = new Date();
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                this.userSubscriptions = response.data
                    .filter(subscription => new Date(subscription.created_at) >= oneYearAgo)
                    .map(SubscriptionDTO => new Subscription(SubscriptionDTO))
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            } catch (e) {
                console.warn(`User Subscriptions API failed.`);
                console.log(e);
            } finally {
                this.loadingUserSubscriptions = false;
            }
        },

        updateCoins(price) {
            const amount = this.unFormat(price, 0) / 100;
            const coinsItem = this.categories['Coins'].find(item => {
                return item.name === 'Coins';
            });
            const coinsPerCurrency = coinsItem.coins_per_currency;

            coinsItem.quantity = Math.round(amount * coinsPerCurrency);

            let exists = false;
            for (const index in this.selectedItems) {
                if (this.selectedItems[index].id === coinsItem.item_id) {
                    exists = true;
                    // If in cart and quantity less than 1, remove. Else, update quantity
                    if (coinsItem.quantity < 1 ) {
                        this.selectedItems.splice(index, 1);
                    } else {
                        this.selectedItems[index] = coinsItem;
                    }
                }
            }

            // If not in cart, add it
            if (!exists && coinsItem.quantity > 0) {
                this.selectedItems.unshift(coinsItem);
            }
        },
        incrementQuantity(item) {
            item.quantity++;
            this.updateCart(item);
        },
        decrementQuantity(item) {
            if (item.quantity !== 0) {
                item.quantity--;
                if (item.quantity === 0) {
                    for (const index in this.selectedItems) {
                        if (this.selectedItems[index].id === item.id) {
                            this.selectedItems.splice(index, 1);
                        }
                    }
                }
            }
        },
        removeItem(item) {
            if (item.name === 'Coins') {
                this.resetCoinsItem();
            }
            for (const index in this.selectedItems) {
                if (this.selectedItems[index].id === item.id) {
                    item.quantity = 0;
                    this.selectedItems.splice(index, 1);
                }
            }
        },
        handleChange(item, event) {
            const qty = parseInt(event.target.value);
            if (!Number.isInteger(qty)) {
                this.removeItem(item);
                return;
            }
            if (qty >= 0) {
                item.quantity = qty;
                this.updateCart(item);
            }
        },
        updateCart(item) {
            let exists = false;
            for (const index in this.selectedItems) {
                if (this.selectedItems[index].id === item.id) {
                    exists = true;
                }
            }
            if (!exists && item.quantity > 0) {
                this.selectedItems.unshift(item);
            }
        },
        sort() {
            if (!this.loadingItems && !this.loadingPaymentMethods && !this.loading && this.pagination.search) {
                if (!this.options.sortBy.length) {
                    this.fetchEntities(`&size=${this.pagination.per_page}&${this.formatSelectedSearchAttributes()}&page=${this.pagination.current_page}`);
                } else {
                    if (this.pagination.current_page !== 1) {
                        this.pagination.current_page = 1;
                    } else {
                        const sortBy = this.options.sortBy[0];
                        const sortDirection = this.options.sortDesc[0] ? 'DESC' : 'ASC';
                        this.fetchEntities(`&size=${this.pagination.per_page}&${this.formatSelectedSearchAttributes()}&page=${this.pagination.current_page}&sort=${sortBy},${sortDirection}`);
                    }
                }
            }
        },
        paymentMethodIcon(paymentMethod) {
            if (paymentMethod === 'Cash') {
                return 'mdi-cash-check';
            } else if (paymentMethod === 'Credit Card') {
                return 'mdi-credit-card-check';
            } else if (paymentMethod === 'Bank') {
                return 'mdi-bank-check';
            } else {
                return 'input';
            }
        },
        resetCoinsItem() {
            this.$set(this, 'coinsValue', '');
            if (this.$refs.coins && this.$refs.coins[0].$el) {
                this.$refs.coins[0].$el.getElementsByTagName('input')[0].value = '';
            }
        },
        reset() {
            this.selectedUser = -1;
            this.selectedPaymentMethod = 0;
            this.selectedItems = [];
            this.users = [];
            this.tab =  Object.keys(this.categories)[0];
            this.step = 1;
            this.resetCoinsItem();

            this.pagination = {
                search: '',
                current_page: 1,
                from: 1,
                last_page: 0,
                path: '',
                per_page: 5,
                to: 0,
                total: 0
            };

            for (const i in this.categories) {
                for (const j in this.categories[i]) {
                    this.$set(this.categories[i][j], 'quantity', 0);
                }
            }

            this.company.r1 = undefined;
            this.company.name = '';
            this.company.pidn = '';
            this.company.address = '';
        },
        formatSelectedSearchAttributes() {
            const searchAttributes = this.selectedSearchAttributes.map(attribute => `${attribute.replaceAll(' ', '_').toLowerCase()}=${this.pagination.search}`);
            return searchAttributes.join('&');
        },
        clearSearch() {
            this.loadingItems = true;
            this.categories = [];
            this.initialLoad = true;
            this.itemsPagination.search = '';
        },
        toggle() {
            this.$nextTick(() => {
                if (this.selectedAllAttributes) {
                    this.selectedSearchAttributes = [];
                } else {
                    this.selectedSearchAttributes = this.searchAttributes.map(({value}) => value);
                }
            })
        },
        refresh() {
            this.fetchItems();
        }
    }

});

</script>

<style lang="scss">

.box-shadow-none {
    box-shadow: none !important;
}

tr.v-data-table__selected {
    background: var(--v-primary-base) !important;
    display: table-row !important;
    color: #fff !important;
    .v-icon {
        color: #fff;
    }
}

.users-table {
    margin-top: -25px;
    border-collapse: separate;
    .v-data-table__empty-wrapper td {
        padding-top: 107px !important;
        padding-bottom: 107px !important;
    }
    tr:not(.v-data-table__empty-wrapper) {
        cursor: pointer;
        td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    .active-checkmark {
        position: absolute !important;
        bottom: 7px;
        left: 5px;
    }
}

.user-sales-body {
  max-height: 180px;
  overflow-y: auto;
  display: block;
}

.user-sales-body tbody {
  display: block;
  width: 100%;
}

.user-sales-body tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.user-sales-data thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.user-sales-data tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.select {
    margin-top: 0 !important;

    .v-input__control {
        height: 26px !important;

        .v-input__slot {
            margin-bottom: 0 !important;

            .v-input--selection-controls__ripple {
                margin-bottom: 0 !important;
            }
        }
    }
}

.v-input--selection-controls.v-input--is-focused .v-input--selection-controls__ripple::before {
    background: grey !important;
    transform: scale(0.85) !important;
}

.position-relative {
    position: relative;
}

section {

    .checkmark {
        position: relative;
        bottom: 2px;
    }

    input[type="radio"] {
        display: none;

        &:not(:disabled) ~ label {
            cursor: pointer;
        }
    }

    label {
        display: block;
        background: var(--v-main-background-base);
        border: 2px solid var(--v-primary-base);
        border-radius: 20px;
        padding: 10px;
        text-align: center;
    }

    input[type="radio"]:checked + label {
        background: var(--v-primary-base);
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
        color: #fff;
        font-weight: bold;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.quantity-input {
    width: 20px !important;
    height: 30px;
    font-size: 14px !important;
    input {
        text-align: center
    }
}

.margin-top-30px {
    margin-top: 30px !important;
}

.categories-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.footer {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

</style>