<template>
    <div>
        <GlobalButton
            :route="'/invoices'"
            :text="$t('entities.invoices.title')"
            icon="receipt"
        />
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.invoices.transactions') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-dialog
                            v-model="restoreDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="error"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.invoices.restoreTransaction') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer/>
                                        <v-btn
                                            @click="restoreDialog = false"
                                            icon
                                            dark
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <v-card class="pb-2">
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                    <tr>
                                                        <td>{{ $t('entities.invoices.attributes.number') }}</td>
                                                        <td
                                                            colspan="2"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toRestoreEntity.number }}
                                                        </td>
                                                        <td>{{ $t('entities.invoices.attributes.byUser') }}</td>
                                                        <td
                                                            colspan="3"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toRestoreEntity.by_user.username }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('generalAttributes.description') }}</td>
                                                        <td
                                                            colspan="2"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toRestoreEntity.description }}
                                                            ({{ toRestoreEntity.coins }} <CoinImage/>)
                                                        </td>
                                                        <td>{{ $t('miscellaneous.arena') }}</td>
                                                        <td
                                                            colspan="3"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toRestoreEntity.arena.name }}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-row class="mt-4">
                                                <v-col cols="12">
                                                    <v-form
                                                        @submit.prevent="restoreTransaction"
                                                        method="POST"
                                                        ref="form"
                                                    >
                                                        <v-row align="center">
                                                            <v-col
                                                                cols="12"
                                                                class="position-relative px-6"
                                                            >
                                                                <v-textarea
                                                                    v-model="toRestoreEntity.restoring_reason"
                                                                    :rules="[required]"
                                                                    :label="$t('entities.invoices.attributes.restoringReason')"
                                                                    prepend-icon="mdi-text"
                                                                    rows="1"
                                                                    auto-grow
                                                                    persistent-hint
                                                                ></v-textarea>
                                                                <span class="required-fields mr-3">*</span>
                                                            </v-col>
                                                        </v-row>
                                                        <ValidationErrors
                                                            v-if="validationErrors.length"
                                                            :errors="validationErrors"
                                                        />
                                                    </v-form>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        @click="restoreDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                        {{ $t('miscellaneous.cancel') }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="restoreTransaction"
                                        :disabled="restoring"
                                        :loading="restoring"
                                        color="error"
                                        text
                                    >
                                        <span>{{ restoring ? $t('entities.invoices.restoringTransaction') : $t('entities.invoices.restoreTransaction') }}</span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.coins="{ item }">
                    {{ addThousandSeparator(item.coins) }}
                    <CoinImage/>
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip
                        top
                        color="transparent"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="openRestoreDialog(item)"
                                v-on="on"
                                :disabled="!!item.restored_transaction_id || item.coins < 0"
                                color="error"
                                class="ml-2"
                                depressed
                                x-small
                                fab
                            >
                                <v-icon
                                    color="white"
                                    small
                                >
                                    restore
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.invoices.restoreTransaction') }}</span>
                        </v-card>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
    </div>
</template>

<script>

import Vue from 'vue';
import {mapActions} from "vuex";
import CoinImage from '@/components/CoinImage.vue';
import NoData from '@/components/crud/NoData.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Transaction from '@/models/shop/Transaction';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';

import currency from '@/mixins/currency';
import isRole from '@/mixins/crud/computed/isRole';
import convertDate from '@/mixins/convert-date';
import user from '@/mixins/crud/computed/user';
import thousandSeparator from '@/mixins/thousand-separator';
import required from '@/mixins/rules/required';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';

export default Vue.extend({
    components: {
        NoData,
        DataTableHeader,
        CoinImage,
        ValidationErrors,
        RefreshButton,
        GlobalButton,
        DataTableSkeletonLoader,
        DataTableFooter,
    },

    mixins: [
        paginationWatch,
        user,
        currency,
        isRole,
        convertDate,
        thousandSeparator,
        axiosCatch,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        restoring: false,
        restoreDialog: false,
        typing: false,
        entities: [],
        validationErrors: [],
        selectedSearchAttributes: ['To User'],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        editedIndex: -1,
        defaultEntity: {
            id: '',
            restored_transaction_id: '',
            number: '',
            by_user: {},
            to_user: {},
            arena: {},
            created_at: '',
            restored_transaction: null,
            restored_by_transaction: null,
            restoring_reason: ''
        },
        toRestoreEntity: {
            id: '',
            restored_transaction_id: '',
            number: '',
            by_user: {},
            to_user: {},
            arena: {},
            created_at: '',
            restored_transaction: null,
            restored_by_transaction: null,
            restoring_reason: ''
        },
    }),

    watch: {
        restoreDialog(val) {
            val || this.closeRestoreDialog();
            if (!val) {
                this.$refs.form.resetValidation();
                this.validationErrors = [];
            }
        },
    },

    computed: {
        headers() {
            const headers = [
                { text: this.$t('entities.invoices.attributes.number'), align: 'left', value: 'number' },
                { text: this.$t('generalAttributes.description'), align: 'left', value: 'description', sortable: false },
                { text: this.$t('generalAttributes.coins'), align: 'left', value: 'coins', sortable: false },
                { text: this.$t('entities.invoices.attributes.byUser'), align: 'left', value: 'by_user.username', sortable: false },
                { text: this.$t('entities.invoices.attributes.toUser'), align: 'left', value: 'to_user.username', sortable: false },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];

            if (!this.$store.getters.arena) {
                headers.splice(2, 0, { text: this.$t('miscellaneous.arena'), align: 'left', value: 'arena.name', sortable: false })
            }
            
            return headers;
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.invoices.attributes.byUser'), value: 'By User' },
                { label: this.$t('entities.invoices.attributes.toUser'), value: 'To User' },
            ]
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {

        ...mapActions(['showSnackbar']),

        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}`) {
            try {
                this.loading = true;
                // If not admin and arena not chosen, fetch non-existing arena
                let query = '&arena_id=';
                if (this.isAdmin) {
                    query += this.$store.getters.arena ? this.$store.getters.arena.id : '';
                } else {
                    query += this.$store.getters.arena ? this.$store.getters.arena.id : '-1';
                }
                const response = await Transaction.api.get(`${query}${append}`);
                this.entities = response.data.data.map(TransactionDTO => new Transaction(TransactionDTO));
                response.data.meta.per_page = parseInt(response.data.meta.per_page);
                Object.assign(this.pagination, response.data.meta);
            } catch (e) {
                console.warn('Transactions API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async restoreTransaction() {
            try {
                this.restoring = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                const data = {
                    by_user: {
                        id: this.user.id,
                        name: this.user.name,
                        email: this.user.email,
                        username: this.user.username,
                    },
                    restoring_reason: this.toRestoreEntity.restoring_reason,
                };

                await Transaction.api.restore(this.toRestoreEntity.id, data);

                this.closeRestoreDialog();
                this.showSnackbar({color:'success', message: this.$t('entities.invoices.notifications.transactionRestore')});
                if (this.pagination.search.length) {
                    this.pagination.search = '';
                } else {
                    await this.fetchEntities();
                }
            } catch (error) {
                this.axiosCatch(error);
            } finally {
                this.restoring = false;
            }
        },
        openRestoreDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toRestoreEntity = {...entity};
            this.restoreDialog = true;
        },
        closeRestoreDialog() {
            this.restoreDialog = false;
            this.toRestoreEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>