<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.companies.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer/>
                        <v-dialog
                            v-model="viewDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.companies.viewEntity') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer/>
                                        <v-btn
                                            @click="viewDialog = false"
                                            icon
                                            dark
                                        >
                                          <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <DialogImage
                                            :image="viewedEntity.image || defaultImg "
                                            :name="viewedEntity.name"
                                        />
                                        <v-card class="px-4 pb-4">
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template
                                                    v-if="viewDialog"
                                                    v-slot:default
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colspan="3"
                                                                class="text-left"
                                                            >
                                                                {{ $t('entities.orders.generalInformation') }}
                                                            </th>
                                                            <th class="text-right">
                                                                #{{ viewedEntity.id }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ viewedEntity.name }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.address') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ viewedEntity.address }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.phone') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ viewedEntity.phone }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                        <br>
                                        <v-card class="px-4 pb-4">
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template
                                                    v-if="viewDialog"
                                                    v-slot:default
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colspan="3"
                                                                class="text-left"
                                                            >
                                                                {{ $t('entities.companies.financialData') }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('entities.companies.attributes.pidn') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ viewedEntity.pidn }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{$t('entities.companies.attributes.coinsTaxes')}}</td>
                                                            <td class="font-weight-bold">
                                                                <ul>
                                                                    <li v-for="tax in viewedEntity.coins_taxes" :key="tax.id">{{ tax.name }}</li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    <tr v-for="(value, key) in viewedEntity.fiscal_data" :key="key">
                                                        <td>{{ key }}</td>
                                                        <td class="font-weight-bold">
                                                            <span v-if="typeof value === 'boolean'"><Boolean :boolean="value"/></span>
                                                            <span v-else-if="key === 'certificatePassword'">{{ value ? '****' : 'null'}} </span>
                                                            <span v-else-if="key === 'certificate'">{{ value ? 'set' : 'null' }}</span>
                                                            <span v-else>{{ value ? value : 'null' }}</span>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip
                        color="transparent"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="openViewDialog(item)"
                                v-on="on"
                                :disabled="loadingView"
                                class="glow ml-2"
                                color="secondary"
                                depressed
                                x-small
                                fab
                            >
                                <template v-if="loadingView">
                                    <ProgressCircular color="secondary"/>
                                </template>
                                <template v-else>
                                    <v-icon
                                        class="white--text"
                                        small
                                    >
                                        pageview
                                    </v-icon>
                                </template>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.companies.viewEntity') }}</span>
                        </v-card>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
    </div>
</template>

<script>

import Vue from 'vue';
import {mapActions} from "vuex";
import NoData from '@/components/crud/NoData.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Company from '@/models/setup/Company';

import required from '@/mixins/rules/required';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';
import ProgressCircular from "@/components/ProgressCircular.vue";
import Boolean from "@/components/Boolean.vue";
import DialogImage from "@/components/DialogImage.vue";
import defaultImg from '@/../public/img/icons/ms-icon-310x310.png';

export default Vue.extend({
    components: {
        DialogImage,
        Boolean,
        ProgressCircular,
        NoData,
        RefreshButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
    },

    mixins: [
        axiosCatch,
        paginationWatch,
        editDialog,
        deleteEntity,
        deleteDialog,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loadingView: false,
        viewDialog: false,
        loading: false,
        showPassword: false,
        saving: false,
        deleting: false,
        typing: false,
        file: null,
        certificateFile: null,
        image: null,
        certificate: null,
        apiURI:  process.env.VUE_APP_ARENA_HUB_BASE_URI,
        route: 'companies',
        entity: 'companies',
        entities: [],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['Name'],
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        viewedEntity: {
            id: '',
            pidn: '',
            coins_taxes: [],
            name: '',
            address: '',
            phone: '',
            fiscal_data: {},
            image: ''
        },
        defaultEntity: {
            id: '',
            pidn: '',
            coins_taxes: [],
            name: '',
            address: '',
            phone: '',
            fiscal_data: {},
            image: ''
        },
        toDeleteEntity: {
            id: '',
            pidn: '',
            coins_taxes: [],
            name: '',
            address: '',
            phone: '',
            fiscal_data: {},
            image: ''
        },
        defaultImg,
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('generalAttributes.address'), align: 'left', value: 'address', sortable: false },
                { text: this.$t('generalAttributes.phone'), align: 'left', value: 'phone', sortable: false },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' }
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        integer() {
            return v => (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer');
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {

        ...mapActions(['showSnackbar']),

        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}`) {
            try {
                this.loading = true;
                const response = await Company.api.get(append);
                const data = response.data;
                this.entities = data.data.map(CompanyDTO => new Company(CompanyDTO));
                this.pagination.current_page = data.metadata.page;
                this.pagination.per_page = data.metadata.size;
                this.pagination.total = data.metadata.count;
                this.pagination.last_page = Math.ceil(data.metadata.count / data.metadata.size);
                this.pagination.from = (data.metadata.page-1)*data.metadata.size + 1;
                this.pagination.to = this.pagination.from + data.metadata.size - 1 > this.pagination.total ? this.pagination.total : this.pagination.from + data.metadata.size - 1;
            } catch (e) {
                console.warn('Companies API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        openViewDialog(entity) {
            this.loadingView = true;
            this.viewDialog = true;
            this.editedIndex = this.entities.indexOf(entity);
            this.viewedEntity = JSON.parse(JSON.stringify(entity));
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.loadingView = false;
        },
        closeViewDialog() {
            this.viewedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            this.certificate = null;
            this.image = null;
            this.editDialog = false;
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>


<style>

.v-select .v-input__slot {
    margin-bottom: 0;
}

</style>
