<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                sm="6"
                class="pb-0"
            >
                <v-row align="center">
                    <v-col
                        cols="auto"
                        class="py-0 pr-0"
                    >
                        <v-tooltip
                            color="transparent"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="refresh"
                                    v-on="on"
                                    :disabled="loading"
                                    class="glow mr-2"
                                    color="primary"
                                    x-small
                                    fab
                                >
                                    <v-icon>
                                        refresh
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card class="elevation-2 px-2 py-1">
                                <small>{{ $t('refreshData') }}</small>
                            </v-card>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="10"
                        class="py-0 pl-2"
                    >
                        <p class="mt-2 mb-0">
                            {{ $t('generated') }}: {{ dateString }}
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog
            v-model="editDialog"
            max-width="1000px"
        >
            <v-card>
                <v-card-text class="px-0 pb-0">
                    <EditToolbar
                        :formTitle="formTitle"
                        :closeEditDialog="closeEditDialog"
                    />
                    <v-container>
                        <v-card class="px-4 pb-4">
                            <v-form
                                @submit.prevent="save"
                                method="POST"
                                ref="form"
                            >
                                <RequiredFields class="mb-4"/>
                                <v-row align="center">
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        class="position-relative pl-5"
                                    >
                                        <v-text-field
                                            :value="editedEntity.max_legend_bonus"
                                            @change="v => (editedEntity.max_legend_bonus = v)"
                                            :rules="[integer, between]"
                                            :label="$t('entities.maxBonuses.attributes.maxLegendBonus')"
                                            type="number"
                                            prepend-icon="mdi-sale"
                                            append-icon="mdi-percent"
                                        >
                                            <template v-slot:append>
                                                <v-icon
                                                    small
                                                    class="mt-1"
                                                >
                                                    mdi-percent
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                        <span class="required-fields">*</span>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        class="position-relative pl-5"
                                    >
                                        <v-text-field
                                            :value="editedEntity.max_rank_bonus"
                                            @change="v => (editedEntity.max_rank_bonus = v)"
                                            :rules="[integer, between]"
                                            :label="$t('entities.maxBonuses.attributes.maxRankBonus')"
                                            type="number"
                                            prepend-icon="mdi-sale"
                                            append-icon="mdi-percent"
                                        >
                                            <template v-slot:append>
                                                <v-icon
                                                    small
                                                    class="mt-1"
                                                >
                                                    mdi-percent
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                        <span class="required-fields">*</span>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        class="position-relative pl-5"
                                    >
                                        <v-text-field
                                            :value="editedEntity.max_reward_bonus"
                                            @change="v => (editedEntity.max_reward_bonus = v)"
                                            :rules="[integer, between]"
                                            :label="$t('entities.maxBonuses.attributes.maxRewardBonus')"
                                            type="number"
                                            prepend-icon="mdi-sale"
                                            append-icon="mdi-percent"
                                        >
                                            <template v-slot:append>
                                                <v-icon
                                                    small
                                                    class="mt-1"
                                                >
                                                    mdi-percent
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                        <span class="required-fields">*</span>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        class="position-relative pl-5"
                                    >
                                        <v-text-field
                                            :value="editedEntity.max_subscription_bonus"
                                            @change="v => (editedEntity.max_subscription_bonus = v)"
                                            :rules="[integer, between]"
                                            :label="$t('entities.maxBonuses.attributes.maxSubscriptionBonus')"
                                            type="number"
                                            prepend-icon="mdi-sale"
                                            append-icon="mdi-percent"
                                        >
                                            <template v-slot:append>
                                                <v-icon
                                                    small
                                                    class="mt-1"
                                                >
                                                    mdi-percent
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                        <span class="required-fields">*</span>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card>
                    </v-container>
                </v-card-text>
                <EditCardActions
                    :save="save"
                    :saving="saving"
                    :closeEditDialog="closeEditDialog"
                />
            </v-card>
        </v-dialog>
        <v-row class="mb-4">
            <v-col
                cols="12"
                sm="6"
            >
                <CardStat
                    :loading="loading"
                    :value="`${entity.max_legend_bonus}%`"
                    :text="$t('entities.maxBonuses.attributes.maxLegendBonus')"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
                <CardStat
                    :loading="loading"
                    :value="`${entity.max_rank_bonus}%`"
                    :text="$t('entities.maxBonuses.attributes.maxRankBonus')"
                    color="orange"
                    width="100px"
                />
            </v-col>
        </v-row>
        <v-row class="mb-4">
            <v-col
                cols="12"
                sm="6"
            >
                <CardStat
                    :loading="loading"
                    :value="`${entity.max_reward_bonus}%`"
                    :text="$t('entities.maxBonuses.attributes.maxRewardBonus')"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
                <CardStat
                    :loading="loading"
                    :value="`${entity.max_subscription_bonus}%`"
                    :text="$t('entities.maxBonuses.attributes.maxSubscriptionBonus')"
                    color="orange"
                    width="100px"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>

import Vue from 'vue';
import {mapActions} from "vuex";
import CardStat from '@/components/CardStat.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import MaxBonuses from '@/models/configuration/MaxBonuses';

import formatDate from '@/mixins/format-date';
import required from '@/mixins/rules/required';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';

export default Vue.extend({
    components: {
        CardStat,
        RequiredFields,
        EditToolbar,
        EditCardActions,
    },

    mixins: [
        formatDate,
        editDialog,
        required,
        axiosCatch,
    ],

    data: () => ({
        dateString: null,
        loading: false,
        saving: false,
        editDialog: false,
        entity: {
            max_legend_bonus: 0,
            max_rank_bonus: 0,
            max_reward_bonus: 0,
            max_subscription_bonus: 0,
        },
        defaultEntity: {
            max_legend_bonus: 0,
            max_rank_bonus: 0,
            max_reward_bonus: 0,
            max_subscription_bonus: 0,
        },
        editedEntity: {
            max_legend_bonus: 0,
            max_rank_bonus: 0,
            max_reward_bonus: 0,
            max_subscription_bonus: 0,
        },
    }),


    computed: {
        formTitle() {
            return this.$t(`entities.maxBonuses.editEntity`);
        },
        between() {
            return v => (v >= 0 && v <= 100) || this.$t('validation.between', { from: 0, to: 100 });
        },
        integer() {
            return v => (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer');
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {

        ...mapActions(['showSnackbar']),

        async fetchMaxBonuses() {
            try {
                this.loading = true;
                const response = await MaxBonuses.api.get();
                const data = response.data;
                this.entity.max_legend_bonus = data.max_legend_bonus;
                this.entity.max_rank_bonus = data.max_rank_bonus;
                this.entity.max_reward_bonus = data.max_reward_bonus;
                this.entity.max_subscription_bonus = data.max_subscription_bonus;
            } catch (e) {
                console.warn(`Max Bonuses API failed.`);
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }
                
                const data = {
                    max_legend_bonus: parseInt(this.editedEntity.max_legend_bonus),
                    max_rank_bonus: parseInt(this.editedEntity.max_rank_bonus),
                    max_reward_bonus: parseInt(this.editedEntity.max_reward_bonus),
                    max_subscription_bonus: parseInt(this.editedEntity.max_subscription_bonus),
                };

                await MaxBonuses.api.update(data)
                    .then(() => {
                        this.closeEditDialog();
                        this.fetchMaxBonuses();
                        this.showSnackbar({color:'success', message: this.$t('entities.maxBonuses.notifications.update')});
                    })
                    .catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`Max Bonuses API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        openEditDialog(entity) {
            this.editedEntity = {...entity};
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editDialog = false;
        },
        refresh() {
            this.fetchMaxBonuses();
            this.dateString = this.formatDate(new Date(), true, true);
        },
    }
})

</script>
