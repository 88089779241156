<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="true"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.users.subscriptions') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                    </v-toolbar>
                </template>
                <template v-slot:item.username="{ item }">
                    {{ item.username }}
                </template>
                <template v-slot:item.name="{ item }">
                    <div>
                        <img
                            :src="item.image"
                            :alt="item.name"
                            width="20px"
                            class="mt-5"
                        />
                        <span style="position: relative; bottom: 15px; left: 5px;">{{ item.name }}</span>
                    </div>
                </template>
                <template v-slot:item.bonus="{ item }">
                    {{ item.bonus }}%
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
                </template>
                <template v-slot:item.expires_at="{ item }">
                    {{ fromUtcDateTimeToCurrent(item.expires_at, true, true) }}
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
    </div>
</template>

<script>

import Vue from 'vue';
import NoData from '@/components/crud/NoData.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Subscription from '@/models/users/Subscription';
import isRole from '@/mixins/crud/computed/isRole';

import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import convertDate from '@/mixins/convert-date';
import User from "@/models/users/User";

export default Vue.extend({
    components: {
        NoData,
        RefreshButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
    },

    mixins: [
        axiosCatch,
        paginationWatch,
        convertDate,
        isRole,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        typing: false,
        entities: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 100,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: [''],
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username', sortable: false },
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name', sortable: false },
                { text: this.$t('entities.ranks.attributes.bonus'), align: 'left', value: 'bonus', sortable: false },
                { text: this.$t('entities.quests.dailyReward'), align: 'left', value: 'daily_reward', sortable: false },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at', sortable: false },
                { text: this.$t('entities.carts.attributes.expiresAt'), align: 'left', value: 'expires_at', sortable: false },
            ];
        },
        searchAttributes() {
            return [];
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `active=true&size=${this.pagination.per_page}&page=${this.pagination.current_page}`) {
            try {
                this.loading = true;
                let response;
                if (this.$store.getters.arena) {
                    response = await Subscription.api.getByArena(this.$store.getters.arena.id, append);
                } else if (this.isAdmin) {
                    response = await Subscription.api.get(append);
                } else {
                    return;
                }
                const data = response.data;
                this.entities = data.map((SubscriptionDTO) => new Subscription(SubscriptionDTO));
                const userIds = [...new Set(data.map((SubscriptionDTO) => SubscriptionDTO.user_id))];

                const userPromises = [];
                for (let i = 0; i < userIds.length; i += 100) {
                    const chunk = userIds.slice(i, i + 100);
                    userPromises.push(User.api.findByIds(chunk));
                }
                const usersResponses = await Promise.all(userPromises);
                const users = usersResponses.flatMap(response => response.data);

                // Assign the corresponding username to each subscription
                this.entities.forEach(subscription => {
                    const user = users.find(user => user.id === subscription.user_id);
                    if (user) {
                        subscription.username = user.username;
                    }
                });
                this.pagination.current_page = 1;
                this.pagination.per_page = 100;
                this.pagination.total = this.entities.length;
                this.pagination.last_page = 1;
                this.pagination.from = 1;
                this.pagination.to = this.entities.length;
                // TODO => Pagination
                /*
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
                */
            } catch (e) {
                console.warn('Subscriptions API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>
